import { supabase } from './supabase';

export const signUp = async (signUpData) => {

    return supabase.auth.signUp({
        email: signUpData.email,
        password:signUpData.password,
       
    },{
        data: signUpData 
    });
}

export const signIn = async (signInData) => {

    return supabase.auth.signIn({
        email: signInData.email,
        password:signInData.password,
       
    });
}

export const signOut = async () => {

    return supabase.auth.signOut();
}

export const forgetPassword = async (email) => {

    return await supabase.auth.api.resetPasswordForEmail(email,{
        redirectTo:"http://www.mogroupltd.com/reset-password"
    });
}

