import React, { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import numeral from "numeral";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { HiOutlineInformationCircle } from "react-icons/hi";

import Modal from "../modal/Modal";
import Footer from "../Footer/Footer";
import DashCashCon from "./DashCashCon";
import Button from "../button/Button";
import { DropdownInput } from "../input/dropdown-input-v2/DropdownInput";
import DashConCash2 from "./DashConCash2";
import DashAccountStatement from "./account statment/DashAccountStatement";
import DashLoanStatement from "./account statment/DashLoanStatement";
import Layout from "../image layout/Layout";

import { useAuth } from "../../providers/auth.provider";
import { getBouncingAmount, roiDaysCount } from "../utilities/helper";
import { supabase } from "../../services/supabase";
import { investmentOption } from "../options";

import "./dash.css";
import { useProductsContext } from "../Context/product_context";
import ProfileImage from "../profile-img/Layout";
import { Link } from "react-router-dom";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  LargeDesktop: {
    breakpoint: { max: 3000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1200 },
    items: 3,
  },
  smallLaptop: {
    breakpoint: { max: 1200, min: 992 },
    items: 2.1,
  },
  tablet: {
    breakpoint: { max: 992, min: 700 },
    items: 2,
  },
  smallTablet: {
    breakpoint: { max: 700, min: 500 },
    items: 1.5,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};

const Dashboard = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [allinvestments, setallinvestments] = useState([]);
  const [type, setType] = useState("Real Estate");
  const [date, setDate] = useState();
  const [allLoans, setallLoans] = useState([]);
  const [investOptions, setInvestOptions] = useState("Real Estate");
  const [loading, setloading] = useState(true);

  const {
    user,
    dates,
    datesInNumbers,
    totalRealestateinvestment,
    getTotalRealestateInvestment,
    totalRealestateROI,
    totalTransportROI,
    gettotalRealestateROI,
    getTotalLoan,
    totalLoan,
    getTotalTransportInvetment,
    totalTransportInvetment,
    getTotalInvestment,
    totalInvestment,
    getTotalROI,
    totalROI,
    gettotalTransportROI,
  } = useAuth();
  const { products } = useProductsContext();

  const BouncingAmount = getBouncingAmount(
    totalROI,
    Math.max(...datesInNumbers),
    date
  );
  const handleDropdownChange = (value) => {
    setInvestOptions(value);
  };

  const sections = [
    {
      element: (
        <DashConCash2
          cashText={`Total Investment ${
            type === "Real Estate" ? "(Real Estate)" : "(Transport)"
          }`}
          amount={`NGN ${
            type === "Real Estate"
              ? numeral(totalRealestateinvestment).format("0,0")
              : numeral(totalTransportInvetment).format("0,0")
          }`}
          className="dash-wid-two"
        />
      ),
      className: "total-income",
    },
    {
      element: (
        <DashConCash2
          cashText="Total Loan"
          amount={`NGN ${numeral(totalLoan).format("0,0")}`}
          className="dash-wid-two"
        />
      ),
      className: "",
    },
    {
      element: (
        <DashConCash2
          cashText="Total Savings"
          amount="NGN 0"
          className="dash-wid-two"
        />
      ),
      className: "",
    },
  ];

  const changeActiveSection = (index) => {
    setActiveSection(index);
  };

  // get investments from supabase
  const getInvestments = () => {
    setloading(true);
    if (investOptions === "Real Estate") {
      setType("Real Estate");
      supabase
        .from("investments")
        .select("*")
        .eq("user", user?.user.user_metadata.email)
        .then((response) => {

          setallinvestments(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    } else {
      setType("Transport");
      supabase
        .from("transportation-investment")
        .select("*")
        .eq("user", user?.user.user_metadata.email)
        .then((response) => {
          setallinvestments(response.data);
          setloading(false);
        })
        .catch((error) => {
          setloading(false);
        });
    }
  };

  const getLoans = () => {
    setloading(true);
    supabase
      .from("Loans")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        setallLoans(response.data);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
      });
  };

  useEffect(() => {
    const endingROIDate = new Date(
      Math.max(
        ...dates.map((element) => {
          const endingDate = new Date(element.endingDate);
          return endingDate;
        })
      )
    );

    const startingROIDate = new Date(
      Math.min(
        ...dates.map((element) => {
          const startDate = new Date(element.startDate);
          return startDate;
        })
      )
    );

    setDate(roiDaysCount(startingROIDate, endingROIDate));
  }, [dates]);

  useEffect(() => {
    getInvestments();
    getLoans();
    getTotalRealestateInvestment();
    gettotalRealestateROI();
    getTotalLoan();
    getTotalTransportInvetment();
    getTotalInvestment();
    getTotalROI();
    gettotalTransportROI();

    // eslint-disable-next-line
  }, [
    totalRealestateinvestment,
    totalTransportInvetment,
    totalTransportROI,
    totalRealestateROI,
    investOptions,
    setallLoans
  ]);

  return (
    <div id="dash-main-con" className="dashboard">
      <section id="dash-right-con">
        <div className="dash-header-main-con">
          <h2 className="dashboard-right-text">
            Welcome {user?.user.user_metadata?.firstName},
          </h2>
          <div className="bell-profile">
            <ProfileImage img={user?.user.user_metadata?.profile_url} />
            <div>
              <FaBell className="bell-con" />
            </div>
          </div>
        </div>

        <div className="dash-mobile-accout-balance-con">
          <div className="dash-mobile-accout-balance">
            <p id="dash-cash-header">Account Balance</p>
            <p id="account-balance-value">
              NGN {numeral(user?.user.user_metadata.balance).format("0,0")}
            </p>
          </div>
          <button className="account-icon" onClick={() => setIsOpen(true)}>
            +
          </button>
        </div>
        <div id="dash-layout">
          <DashCashCon
            cashText="Total Investment"
            amount={`NGN ${numeral(totalInvestment).format("0,0")}`}
            className="dash-wid-one"
          />
          <DashCashCon
            cashText="Total ROI"
            amount={`NGN ${numeral(totalROI).format("0,0")}`}
            className="dash-wid-one"
          />
          <DashCashCon
            cashText="Account Balance"
            amount={`NGN ${numeral(user?.user.user_metadata.balance).format(
              "0,0"
            )}`}
            className="dash-wid-one dash-desktop-acc"
          />
        </div>

        <div className="dash-btn">
          <Button
            text="Fund Account"
            icon="+"
            className="btn colored-btn fund-btn"
            onClick={() => setIsOpen(true)}
          />
          <div className="roi-countdown-con">
            {" "}
            <div className="bounce">
              <p>ROI</p>
              <p>DAY {isNaN(date) ? 0 : date}</p>{" "}
              <p>NGN {numeral(BouncingAmount).format("0,0")}</p>
            </div>
          </div>
        </div>
        <div className="dashboard-select-layout1">
          <div className="dash-select-con1">
            <DropdownInput
              defaultValue="Real Estate"
              onChange={handleDropdownChange}
              options={investmentOption}
            />
          </div>
          <div className="dashboard-select-layout2">
            <div className="dash-select-con2">
              <button className="dash-sel-btn dash-btn dash-saving-btn">
                {" "}
                <HiOutlineInformationCircle className="dash-saving-icon" />
                Savings
              </button>
            </div>
            <div className="dash-select-con2">
              <Link to="/dashboard/withdrawROI">
                <button className="dash-sel-btn dash-btn">Withdraw ROI</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="dashboard-select-layout">
          {sections.map((section, index) => {
            return (
              <div
                key={index}
                index={index}
                onClick={() => changeActiveSection(index)}
                className={`dash-active-btn ${section.className} ${
                  activeSection === index ? "dash-active-btn-select" : ""
                }`}
              >
                {section.element}
              </div>
            );
          })}
        </div>
        {activeSection === 0 ? (
          <DashAccountStatement
            loading={loading}
            type={type}
            options={allinvestments}
            text="Total Income"
          />
        ) : activeSection === 1 ? (
          <DashLoanStatement
            options={allLoans}
            loading={loading}
            text="Total Loans"
          />
        ) : (
          <DashAccountStatement
            loading={false}
            text="Total Savings"
            className="view-display"
          />
        )}
        <div className="dash-carousel">
          <Carousel responsive={responsive}>
            {products?.slice(0, 6)?.map((product) => {
              return (
                <Layout
                  key={product.id}
                  {...product}
                  id1="no-display"
                  id2="display"
                  id3="no-display"
                />
              );
            })}
          </Carousel>
        </div>
        <Footer />
      </section>
      {isOpen && (
        <Modal
          setIsOpen={setIsOpen}
          modalId1="modal-block"
          modalId2="modal-none"
          modalId3="modal-none"
        />
      )}
    </div>
  );
};

export default Dashboard;
