import React, { useEffect } from 'react'
import { FaBell } from 'react-icons/fa'
import AllItems from '../../AllItems/Transport allitems/Allitems';
import { useProductsContext } from '../../Context/product_context';
import Footer from '../../Footer/Footer';

const Transport = () => {
    const { fetchTransport, transports } = useProductsContext();

    useEffect(() => {
        fetchTransport();
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <div id="dash-main-con">
            <div className='get-loan-right'>
                <section className='get-loan-right-main'>
                    <div className="dash-header-main-con">
                        <h2 className="real-estate-header-text">Transport</h2>
                        <div className="bell-profile">
                            <div>
                                <FaBell className="bell-con" />
                            </div>
                        </div>
                    </div>
                    {transports !== undefined? <AllItems items={transports} admin={true}/> : <div></div>}
                    
                </section>
                <div className="dash-footer-con">

                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Transport