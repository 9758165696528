import React, { useEffect, useState } from "react";
import { Table } from "antd";

import { investmentOption } from "../../../options";
import { useAuth } from "../../../../providers/auth.provider";
import { supabase } from "../../../../services/supabase";
import { DropdownInput } from "../../../input/dropdown-input-v2/DropdownInput";
import numeral from "numeral";
import { Spinner } from "../../../spinner/Spinner";
import { Link, useParams } from "react-router-dom";

const AllIncome = ({ admin, userEmail }) => {
  const { email } = useParams();
  const [investOptions, setInvestOptions] = useState("Real Estate");
  const [allinvestments, setallinvestments] = useState([]);
  const [columns, setcolumns] = useState();
  const [loading, setloading] = useState(true);

  const { user } = useAuth();

  const handleDropdownChange = (value) => {
    setInvestOptions(value);
  };

  const getInvestments = () => {
    if (admin === true) {
      setloading(true);
      if (investOptions === "Real Estate") {
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data", "itemName"],
            key: ["meta_data", "itemName"],
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "dueDate"],
            key: ["meta_data", "dueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("investments")
          .select("*")
          .then((response) => {
            setallinvestments(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      } else {
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data", "itemName"],
            key: ["meta_data", "itemName"],
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "yearlyDueDate"],
            key: ["meta_data", "yearlyDueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("transportation-investment")
          .select("*")
          .then((response) => {
            console.log("tans", response.data);
            setallinvestments(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    } else if (userEmail) {
      setloading(true);
      if (investOptions === "Real Estate") {
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data", "itemName"],
            key: ["meta_data", "itemName"],
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "dueDate"],
            key: ["meta_data", "dueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("investments")
          .select("*")
          .eq("user", email)
          .then((response) => {
            setallinvestments(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      } else {
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data", "itemName"],
            key: ["meta_data", "itemName"],
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "yearlyDueDate"],
            key: ["meta_data", "yearlyDueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("transportation-investment")
          .select("*")
          .eq("user", email)
          .then((response) => {
            setallinvestments(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    } else {
      setloading(true);
      if (investOptions === "Real Estate") {
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data", "itemName"],
            key: ["meta_data", "itemName"],
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "dueDate"],
            key: ["meta_data", "dueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("investments")
          .select("*")
          .eq("user", user?.user.user_metadata.email)
          .then((response) => {
            console.log("realestate", response.data);
            setallinvestments(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      } else {
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data", "itemName"],
            key: ["meta_data", "itemName"],
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "yearlyDueDate"],
            key: ["meta_data", "yearlyDueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("transportation-investment")
          .select("*")
          .eq("user", user?.user.user_metadata.email)
          .then((response) => {
            setallinvestments(response.data);
            console.log("transport", response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    }
  };

  useEffect(() => {
    getInvestments();
    // eslint-disable-next-line
  }, [investOptions]);

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="get-loan-right-main">
          {admin === true ? (
            <div className="dash-header-main-con">
              <p className="dash-account-tran">Income</p>
              <Link to="/admin" className="dash-account-statement-all-link">
                Back to home
              </Link>
            </div>
          ) : userEmail === true ? (
            <div className="dash-header-main-con">
              <p className="dash-account-tran">Income</p>
              <Link
                to="/admin-users"
                className="dash-account-statement-all-link"
              >
                All user
              </Link>
            </div>
          ) : (
            <div className="dash-header-main-con">
              <p className="dash-account-tran">Income</p>
              <Link to="/dashboard" className="dash-account-statement-all-link">
                Back to DashBoard
              </Link>
            </div>
          )}

          <div className="dashboard-select-layout">
            <div className="dash-select-con">
              <DropdownInput
                defaultValue="Real Estate"
                onChange={handleDropdownChange}
                options={investmentOption}
              />
            </div>
          </div>

          <div className="dash-antd-table">
            <Table
              columns={columns}
              loading={{
                indicator: <Spinner />,
                spinning: loading,
              }}
              dataSource={allinvestments}
              scroll={{ x: 1000 }}
              sticky
              pagination={false}
              rowKey="id"
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default AllIncome;
