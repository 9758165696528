import React, { useEffect } from "react";

import AllItems from "../../AllItems/AllItems";
import Categories from "../../Categories/Categories";
import { useFilterContext } from "../../Context/filter_context";
import { getUniqueValues } from "../../utilities/helper";
import { FaBell } from "react-icons/fa";
import Footer from '../../Footer/Footer';
import "./style/real-estate.css"
import ProfileImage from "../../profile-img/Layout";
import { useAuth } from "../../../providers/auth.provider";

const RealEstate = () => {
  const { user } = useAuth();
  const { filtered_products: products } = useFilterContext();

  const {
    updateFilters,
    all_products,
  } = useFilterContext();

  const categories = getUniqueValues(all_products, "location");
  useEffect(
    () => {
      updateFilters({ target: { name: "location", textContent: "all" } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div id="dash-main-con">
      <div className='get-loan-right'>
        <section className='get-loan-right-main'>
          <div className="dash-header-main-con">
            <h2 className="real-estate-header-text">Real Estate</h2>
            <div className="bell-profile">
              <ProfileImage img={user?.user.user_metadata?.profile_url} />
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>
          <Categories categories={categories} updateFilters={updateFilters} />
          <AllItems items={products} />
        </section>
        <div className="dash-footer-con">

          <Footer />
        </div>
      </div>
    </div>

  );
};

export default RealEstate;
