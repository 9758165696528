import React from 'react'
import Investment from '../../components/Admin Dashboard/Real Estate/Investment'

const InvestmentPage = () => {
    window.scrollTo(0, 0);
    return (
        <Investment />
    )
}

export default InvestmentPage