import React from "react";
import { Link } from "react-router-dom";
import logo from "../../Asset/Icons/logo.jpeg";
import "./styles/navbar.css";

const Nav = ({reset}) => {
  return (
    <>
      <nav className="container nav">
        <div className="nav-logo-con">
        <img src={logo} alt="logo" className="nav-logo"/>
        </div>
        <div className="nav-link-con">
          {reset === true ? <></> : <><Link to="/login" className="nav-link nav-link-space"><div>Sign in</div></Link>
          <Link to="/register" className="nav-link"><div>Sign up</div></Link></> }
        </div>
      </nav>
    </>
  );
};

export default Nav;
