import React from 'react'

import "./style/checkbox.css"

const CheckBox = ({ checked, onClick }) => {
    return (
      <div>
        {!checked ? (
          <div
            className="remember-me-checkbox-checked"
            onClick={onClick}
            aria-hidden="true"
          >
            <div className="remember-me-checkbox" />
          </div>
        ) : (
          <div
            className="remember-me-checkbox-checked"
            onClick={onClick}
            aria-hidden="true"
          />
        )}
      </div>
    );
}

export default CheckBox