import React, { useState, useEffect } from "react";
import numeral from "numeral";
import Button from "../../../button/Button";
import { DropdownInput } from "../../../input/dropdown-input-v2/DropdownInput";
import "../style/invest.css";
import ProfileImage from "../../../profile-img/Layout";
import { useParams } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import Modal from "../../../modal/Modal";
import Footer from "../../../Footer/Footer";
import { useAuth } from "../../../../providers/auth.provider";
import { durationOption } from "../../../options";
import { toast } from "react-toastify";
import { useFilterContext } from "../../../Context/filter_context";
import { getFullDateInYears } from "../../../utilities/helper";

const Investment = () => {
  const { user } = useAuth();

  const { id } = useParams();

  const { filtered_products: products } = useFilterContext();

  const product = products.find((product) => String(product.id) === id);

  const [isOpen, setIsOpen] = useState(false);
  const [year, setYear] = useState(0);
  const [ROI, setROI] = useState(0);
  const [userROI, setUserROI] = useState(0);
  const [dueDate, setDueDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(0);

  const investObj = {
    amount: product?.property?.amount,
    year,
    ROI,
    userROI,
    startDate,
    dueDate,
    endDate,
    itemName: product?.property.name + ", " + product?.property.location,
    name:
      user?.user.user_metadata?.firstName +
      " " +
      user?.user.user_metadata?.lastName,
    email: user?.user.user_metadata?.email,
    type: "real-estate",
    user,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const yearsOnChange = (e) => {
    setYear(e);
    setEndDate(Number(e) * 365);
  };

  const calROI = (amount, year) => {
    let currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setStartDate(`${currentDay}/${currentMonth}/${currentYear}`);

    let mainROI;

    if (year === 0) {
      setROI(0);
      setDueDate("");
      setUserROI(0);
    } else if (year === "1") {
      let _roi = Number(amount) * 0.3;
      mainROI = Number(amount) + _roi;
      setROI(mainROI);
      setUserROI(_roi);
      setDueDate(getFullDateInYears(1));
    } else {
      let firstYear = Number(amount) + Number(amount) * 0.3;
      let _roi = Number(firstYear) * 0.3;
      mainROI = Number(firstYear) + _roi;
      setROI(mainROI);
      setUserROI(_roi);
      setDueDate(getFullDateInYears(2));
    }
  };

  const OpenModal = () => {
    if (year === 0) {
      toast.error("Please fill out the form");
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    calROI(product?.property?.amount, year);
  }, [product?.property?.amount, year]);

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="invest-main-con">
          <div className="dash-header-main-con">
            <p className="invest-header-text">Invest</p>
            <div className="bell-profile">
              <ProfileImage img={user?.user.user_metadata?.profile_url} />
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>
          <form className="invest-form-layout" onSubmit={handleSubmit}>
            <div className="invest-form">
              <input
                type="text"
                value={`${user?.user.user_metadata?.firstName} ${user?.user.user_metadata?.lastName}`}
                disabled
                className="form-control-input"
              />
              <input
                type="text"
                value={`${user?.user.user_metadata?.phoneNumber}`}
                disabled
                className="form-control-input"
              />
              <input
                type="text"
                value={`${numeral(product?.property?.amount).format("0,0")}`}
                disabled
                className="form-control-input"
              />
              <input
                type="text"
                value={`${
                  product?.property?.name + ", " + product?.property?.location
                }`}
                disabled
                className="form-control-input"
              />

              <div className="input-container invest-form-space">
                <DropdownInput
                  placeHolder="Duration"
                  onChange={yearsOnChange}
                  options={durationOption}
                  className="invest-form-space"
                />
              </div>

              <div className="mobile-roi">
                <div className="invest-roi-con">
                  <div className="invest-roi">
                    <p className="invest-roi-text">ROI</p>
                    <div className="invest-roi-amount">
                      <p>NGN {numeral(userROI).format("0,0")}</p>
                    </div>
                    <p className="invest-roi-date">{dueDate}</p>
                  </div>
                </div>
              </div>

              <div className="invest-form-btn">
                <Button
                  text="Submit"
                  className="colored-btn"
                  onClick={OpenModal}
                />
              </div>
            </div>
            <div className="desktop-roi invest-roi-con">
              <div className="invest-roi">
                <p className="invest-roi-text">ROI</p>
                <div className="invest-roi-amount">
                  <p>NGN {numeral(userROI).format("0,0")}</p>
                </div>
                <p className="invest-roi-date">{dueDate}</p>
              </div>
            </div>
          </form>
          {isOpen && (
            <Modal
              invest_data={investObj}
              setIsOpen={setIsOpen}
              modalId2="modal-block"
              modalId1="modal-none"
              modalId3="modal-none"
              investmentAmount={product.property.amount}
              type="Real Estate"
            />
          )}
        </section>
        <div className="dash-footer-con">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Investment;
