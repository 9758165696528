import React from 'react'
import Transport from '../components/DashBoard/transport/Transport';

const TransportPage = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Transport />
        </>
    )
}

export default TransportPage