import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../button/Button";
import { DropdownInput } from "../../input/dropdown-input-v2/DropdownInput";
import DashCashCon from "../DashCashCon";
import "./styles/getLoan.css";
import { Link } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import Footer from "../../Footer/Footer";
import { supabase } from "../../../services/supabase";
import { useAuth } from "../../../providers/auth.provider";
import { bankOption, periodOption } from "../../options";
import numeral from "numeral";
import { toast } from "react-toastify";
import { config } from "../../../app.config";
import ProfileImage from "../../profile-img/Layout";
import { getFullDateInMonth3 } from "../../utilities/helper";

const GetLoan = () => {
  const {
    user,
    totalRealestateinvestment,
    getTotalRealestateInvestment,
    totalRealestateROI,
    totalTransportROI,
    gettotalRealestateROI,
    getTotalTransportInvetment,
    totalTransportInvetment,
    getTotalInvestment,
    totalInvestment,
    getTotalROI,
    totalROI,
    gettotalTransportROI,
    totalLoan,
    getTotalLoan,
  } = useAuth();
  const initialFormData = {
    amount: 0,
    period: "",
    accountNumber: user?.user.user_metadata?.accountNumber
      ? `${user?.user.user_metadata?.accountNumber}`
      : "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [dueDate, setDueDate] = useState();
  const history = useNavigate();
  const [accName, setAccName] = useState(
    user?.user.user_metadata?.accountName
      ? `${user?.user.user_metadata?.accountName}`
      : ""
  );
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState(
    user?.user.user_metadata?.bank ? `${user?.user.user_metadata?.bank}` : ""
  );

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const getAccountName = async (acc_number, bank_code) => {
    const url = `https://api.paystack.co/bank/resolve?account_number=${acc_number}&bank_code=${bank_code}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${config.PAYSTACK_SECRET_KEY}`,
        },
      })
      .then((response) => {
        setAccName(response.data.data.account_name);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Couldn't get account name");
      });
  };

  const handlePaymentPeriodChange = (value) => {
    setFormData({ ...formData, period: value });
  };

  const handleChange = (value, key) => {
    setBank(value);
    console.log(key.id);

    getAccountName(formData.accountNumber, key.id);
  };

  useEffect(() => {
    const calMonthlyDate = () => {
      if (formData.period === 0) {
        setDueDate("");
      } else if (formData.period === "1") {
        setDueDate(getFullDateInMonth3(1));
      } else if (formData.period === "2") {
        setDueDate(getFullDateInMonth3(2));
      } else if (formData.period === "3") {
        setDueDate(getFullDateInMonth3(3));
      } else if (formData.period === "4") {
        setDueDate(getFullDateInMonth3(4));
      } else if (formData.period === "5") {
        setDueDate(getFullDateInMonth3(5));
      } else {
        setDueDate(getFullDateInMonth3(6));
      }
    };

    calMonthlyDate();
  }, [formData.period]);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (
      formData.amount === 0 ||
      bank === "" ||
      formData.period === "" ||
      formData.accountNumber === "" ||
      accName === ""
    ) {
      toast.error("Please fill out the form");
      setLoading(false);
    } else {
      if (+totalLoan + +formData.amount > totalInvestment * 0.5) {
        toast.error(
          "Total loan should be should be less than 50% 0f your total investment"
        );
        setLoading(false);
      } else {
        const meta_data = {
          amount: formData.amount,
          period: formData.period,
          bank: bank,
          accountNumber: formData.accountNumber,
          accountName: accName,
          email: user?.user.user_metadata?.email,
          dueDate: dueDate,
          user,
        };

        const payment = {
          amount: formData.amount,
          period: formData.period,
        };

        const bank_details = {
          bank: bank,
          accountNumber: formData.accountNumber,
          accountName: accName,
        };

        supabase
          .from("Loans")
          .insert({
            user: user?.user.user_metadata?.email,
            payment: payment,
            bank_details: bank_details,
            user_data: user?.user.user_metadata,
            meta_data: meta_data,
          })
          .then((response) => {
            supabase.auth.update({
              data: {
                bank: response.data[0].bank_details.bank,
                accountName: response.data[0].bank_details.accountName,
                accountNumber: response.data[0].bank_details.accountNumber,
              },
            });
            if (response.error == null) {
              toast.success("Sucessful");
              history("/dashboard");
              setFormData({
                amount: 0,
                period: "",
                accountNumber: "",
              });
              setAccName("");
              setBank("");
              setLoading(false);
            }
          })
          .catch((error) => {
            toast.error("An error occured");
            setLoading(false);
          });
      }
    }
  };

  useEffect(
    () => {
      getTotalRealestateInvestment();
      gettotalRealestateROI();
      getTotalTransportInvetment();
      getTotalInvestment();
      getTotalROI();
      gettotalTransportROI();
      getTotalLoan();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      totalRealestateinvestment,
      totalTransportInvetment,
      totalLoan,
      totalTransportROI,
      totalRealestateROI,
    ]
  );

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="get-loan-right-main">
          <div className="dash-header-main-con">
            <p className="get-loan-header-text">Get Loan</p>
            <div className="bell-profile">
              <Link to="/dashboard" className="view-dashboard">
                <p>View dashboard</p>
              </Link>
              <ProfileImage img={user?.user.user_metadata?.profile_url} />
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>

          <div id="dash-layout">
            <DashCashCon
              cashText="Total Investment"
              amount={`NGN ${numeral(totalInvestment).format("0,0")}`}
              className="dash-wid-one"
            />
            <DashCashCon
              cashText="Total ROI"
              amount={`NGN ${numeral(totalROI).format("0,0")}`}
              className="dash-wid-one"
            />
            <DashCashCon
              cashText="Account Balance"
              amount={`NGN ${numeral(user?.user.user_metadata.balance).format(
                "0,0"
              )}`}
              className="dash-wid-one"
            />
          </div>

          <form className="get-loan-form-layout" onSubmit={handleSubmit}>
            <input
              type="number"
              placeholder="Select amount"
              className="form-control-input form-control-input2"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
            />
            <DropdownInput
              placeHolder="Select payment period"
              onChange={handlePaymentPeriodChange}
              options={periodOption}
            />
            <p className="get-loan-form-text">Recieving account details</p>
            <input
              type="number"
              placeholder="Account Number"
              className="form-control-input2"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleInputChange}
            />
            <DropdownInput
              placeHolder="Select bank"
              defaultValue={
                user?.user.user_metadata?.bank
                  ? `${user?.user.user_metadata?.bank}`
                  : "Select bank"
              }
              onChange={handleChange}
              options={bankOption}
            />
            <input
              type="text"
              value={accName}
              disabled
              className="form-control-input"
            />
            <div className="form-btn-con">
              <Button
                text="Submit"
                className="colored-btn form-control-btn"
                loading={loading}
              />
            </div>
          </form>
        </section>
        <div className="dash-footer-con">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default GetLoan;
