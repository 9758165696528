import React from 'react'
import Investment from '../../components/DashBoard/invest/transport investment/Investment';

const TransportInvestment = () => {
    window.scrollTo(0, 0);
    return (
      <>
        <Investment />
      </>
    )
}

export default TransportInvestment