import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Button from '../../button/Button'
import Input from '../../input/Input'
import lock from "../../../Asset/Icons/lock.svg";
import { supabase } from '../../../services/supabase';
import Footer from '../../Footer/Footer';
import { useAuth } from "../../../providers/auth.provider";

const ResetPassword = () => {
  const history = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();

  console.log(user);

 
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const HandelSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      supabase.auth.update({
        password: password,
      })
      history('/');
      toast.success("Password reset successful");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Sorry Error occured");
    }
  };

  return (
    <>
      <div >
        <div className="reset-form-main">
        <form className="reset-form " onSubmit={(e) => { e.preventDefault() }}>
          <h1 className="form-header form-space">Reset Password</h1>
          <Input
            icon={lock}
            type={passwordType}
            className="input1"
            conClass="input-space"
            placeholder="Password"
            name="password"
            value={password}
            togglePassword={togglePassword}
            passwordType={passwordType}
            onChange={(e) => setPassword(e.target.value)}
            id="eye-show"
          />
          <div className="form-btn-con reset-btn"> <Button text="Reset Password" className="colored-btn" onClick={HandelSubmit} loading={loading} /></div>
        </form>
        </div>
        <div className='reset-footer-con'><Footer /></div>
      </div>
    
    </>
  )
}

export default ResetPassword