import React from 'react'
import AllIncome from '../components/DashBoard/account statment/view-all-income/AllIncome';

const AllIncomePage = ({admin, email}) => {
    window.scrollTo(0, 0);
    return (
        <>
            <AllIncome admin={admin} userEmail={email}/>
        </>
    )
}

export default AllIncomePage
