import React, { useState } from 'react'
import { FaBell } from 'react-icons/fa'
import Footer from '../../Footer/Footer';
import AddRealEstate from './AddRealEstate';
import AddTransport from './AddTransport';
import "./styles/addInvest.css"

const AddInvestments = () => {
  const sections = ["Real Estate", "Transport"]

  const [activeSection, setActiveSection] = useState(0);

  const changeActiveSection = (index) => {
    setActiveSection(index);
  };


  return (
    <div id="dash-main-con">
      <div className='get-loan-right'>
        <section className='get-loan-right-main'>
          <div className="dash-header-main-con">
            <h2 className="real-estate-header-text"> Add Investments</h2>
            <div className="bell-profile">
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>
          <div className="admin-select-layout" >
                    {sections.map((section, index) => {
                        return (
                            <div
                                key={index}
                                index={index}
                                onClick={() => changeActiveSection(index)}
                                className={`admin-select-btn ${activeSection === index ? " admin-selected-btn" : ""
                                    }`}>
                                {section}
                            </div>
                        )
                    })}
                </div>

                {activeSection === 0 ? <AddRealEstate  /> : <AddTransport />}
        </section>
        <div className="dash-footer-con">

          <Footer />
        </div>
      </div>
    </div>
  )
}

export default AddInvestments