import moment from "moment";

export const getUniqueValues = (data, type) => {
  let unique = data.map((item) => item.property[type]);
  if (type === "location") {
    unique = unique.flat();
  }

  return ["all", ...new Set(unique)];
};

export const daysSince = (date) => {
  let initialDate = date; // Attention: month is zero-based
  let now = Date.now();
  let difference = now - initialDate;
  let millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.floor(difference / millisecondsPerDay);
};

export const roiDaysCount = (startDate, endDate) => {
  let initialDate = startDate; // Attention: month is zero-based
  let now = Date.now();
  let millisecondsPerDay = 24 * 60 * 60 * 1000;
  if (now >= endDate) {
    let endDifference = endDate - initialDate;
    return(Math.floor(endDifference / millisecondsPerDay));
  } else {
    let difference = now - initialDate;
    return Math.floor(difference / millisecondsPerDay);
  }
};

export const getBouncingAmount = (roi, number, date) => {
  const singleAmount = roi / number;

  return singleAmount * date;
};

export const getFullDateInYears = (number) => {
  let currentDate = new Date();
  const fullDate = currentDate.setDate(currentDate.getDate() + 365 * number);
  const day = moment(fullDate).format("DD");
  const month = moment(fullDate).format("MM");
  const theYear = moment(fullDate).format("YYYY");

  return `${day}/${month}/${theYear}`;
};

export const getFullDateInMonth = (number) => {
  let currentDate = new Date();
  const fullDate = currentDate.setDate(currentDate.getDate() + 30 * number);
  const day = moment(fullDate).format("DD");
  const month = moment(fullDate).format("MM");
  const theYear = moment(fullDate).format("YYYY");

  return `${day}/${month}/${theYear}`;
};

export const getFullDateInMonth2 = (number, daysRemaining) => {
  let currentDate = new Date();
  const fullDate = currentDate.setDate(
    currentDate.getDate() + 30 * number - daysRemaining
  );
  const day = moment(fullDate).format("DD");
  const month = moment(fullDate).format("MM");
  const theYear = moment(fullDate).format("YYYY");

  return `${day}/${month}/${theYear}`;
};

export const getFullDateInMonth3 = (number) => {
  let currentDate = new Date();
  const fullDate = moment(currentDate).add(number, "M");
  const day = moment(fullDate).format("DD");
  const month = moment(fullDate).format("MM");
  const theYear = moment(fullDate).format("YYYY");

  return `${day}/${month}/${theYear}`;
};

export const getFullDateInDays = (number) => {
  let currentDate = new Date();
  const fullDate = currentDate.setDate(currentDate.getDate() + number);
  const day = moment(fullDate).format("DD");
  const month = moment(fullDate).format("MM");
  const theYear = moment(fullDate).format("YYYY");

  return `${day}/${month}/${theYear}`;
};

export const getDueDate = (date) => {
  const str = date;

  const [day, month, year] = str.split("/");

  const theDate = new Date(+year, month - 1, +day).setHours(0, 0, 0, 0);
  let todays = new Date();
  const todaysDay = todays.getDate();
  const todaysMonth = todays.getMonth();

  const todaysYear = todays.getFullYear();
  let today = new Date(todaysYear, todaysMonth, todaysDay).setHours(0, 0, 0, 0);

  if (theDate < today) {
    return true;
  } else if (theDate === today) {
    return true;
  } else {
    return false;
  }
};
