import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ProductsProvider } from "./components/Context/product_context";
import { FilterProvider } from "./components/Context/filter_context";
import { AuthProvider } from "./providers/auth.provider";
import { AdminProvider } from "./providers/admin.provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <AdminProvider>
        <ProductsProvider>
          <FilterProvider>
            <App />
          </FilterProvider>
        </ProductsProvider>
      </AdminProvider>
    </AuthProvider>
  </React.StrictMode>
);
