import React from 'react'
import Nav from '../components/navbar/Navbar';
import ResetPassword from '../components/signin/reset-password/ResetPassword';

const ResetPasswordPage = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Nav reset={true}/>
            <ResetPassword />
        </>
    )
}

export default ResetPasswordPage