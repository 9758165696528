import React from "react";
import { Select } from "antd";
import "antd/dist/antd.min.css";
import "./categories.css";

const Categories = ({ categories, updateFilters }) => {
  const { Option } = Select;
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <div className="category-select-con">
      <Select
        className="categories-select-input"
        onChange={handleChange}
        defaultValue="all"
      >
        {categories.map((c, index) => {
          return (
            <Option key={index} value={c}>
              <button
                className="category-btn"
                type="button"
                name="location"
                id="link"
                key={index}
                onClick={updateFilters}
              >
                {c}
              </button>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default Categories;
