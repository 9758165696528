import { supabase } from '../services/supabase'
import React, { useContext, useState } from 'react'

const AdminContext = React.createContext()

export const AdminProvider = ({ children }) => {
    const [totalRealestateinvestment, setTotalRealestateinvestment] = useState([])
    const [totalRealestateROI, setTotalRealestateROI] = useState([])
    const [totalTransportInvetment, setTotalTransportationInvestment] = useState([])
    const [totalTransportROI, setTotalTransportROI] = useState([])
    const [totalInvestment, setTotalInvestment] = useState([])
    const [totalROI, setTotalROI] = useState([])
    const [totalLoan, setTotalLoan] = useState([])
    const [totalAccountBalance, setTotalAccountBalance] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [loading, setloading] = useState(true)
    
    const getTotalRealestateInvestment = () => {
        supabase
            .from("investments")
            .select("*")
            .then(response => {
                var investment = response.data;
                // conso

                function total() {
                    var total = 0;
                    for (let i = 0; i < investment.length; i++) {
                        total += +(investment[i].property.amount);
                    }
                    return total;
                }
                return setTotalRealestateinvestment(total())
            })
            .catch(error => {
            })

    }

    const getTotalTransportInvetment = () => {
        supabase
            .from("transportation-investment")
            .select("*")
            .then(response => {
                var investment = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < investment.length; i++) {
                        total += +(investment[i].transport.amount);
                    }
                    return total;
                }
                return setTotalTransportationInvestment(total())
            })
            .catch(error => {
            })

    }

    const gettotalRealestateROI = () => {
        supabase
            .from("investments")
            .select("*")
            .then(response => {
                var ROI = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < ROI.length; i++) {
                        total += +(ROI[i].property.userROI);
                    }
                    return total;
                }
                return setTotalRealestateROI(total())
            })
            .catch(error => {
            })

    }

    const gettotalTransportROI = () => {
        supabase
            .from("transportation-investment")
            .select("*")
            .then(response => {
                // console.log('transport roi', response)
                var ROI = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < ROI.length; i++) {
                        total += +(ROI[i].transport.userROI);
                    }
                    return total;
                }
                return setTotalTransportROI(total())
            })
            .catch(error => {
            })

    }

    const getTotalLoan = () => {
        supabase
            .from("Loans")
            .select("*")
            .then(response => {
                var loan = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < loan.length; i++) {
                        total += +(loan[i].payment.amount);
                    }
                    return total;
                }

                return setTotalLoan(total())
            })
            .catch(error => {
            })

    }


    const getTotalAccountBalance = () => {
        supabase
            .from("users")
            .select("*")
            .then(response => {
                var balance = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < balance.length; i++) {
                        total += +(balance[i].meta_data.balance);
                    }
                    return total;
                }

                return setTotalAccountBalance(total())
            })
            .catch(error => {
            })

    }

    const getTotalInvestment = () => {
        const totInvest = totalRealestateinvestment + totalTransportInvetment
        setTotalInvestment(totInvest)
    }

    const getTotalROI = () => {
        const totROI = totalRealestateROI + totalTransportROI
        setTotalROI(totROI)
    }

    const getAllUsers = () => {
        setloading(true)
        supabase
            .from("users")
            .select("*")
            .then(response => {
                setloading(false)
                return setAllUsers(response.data)
            })
            .catch(error => {
                setloading(false)
            })

    }

    const value = {
        totalRealestateinvestment,
        getTotalRealestateInvestment,
        totalRealestateROI,
        gettotalRealestateROI,
        totalLoan,
        getTotalLoan,
        getTotalTransportInvetment,
        totalTransportInvetment,
        getTotalInvestment,
        totalInvestment, 
        totalTransportROI,
        getTotalROI,
        gettotalTransportROI,
        totalROI, 
        getTotalAccountBalance,
        totalAccountBalance,
        getAllUsers,
        allUsers,
        loading
    }
    
    return (
        <AdminContext.Provider value={value}>
            {children}
        </AdminContext.Provider>
    )
}

export function useAdmin() {
    return useContext(AdminContext)
}