import React from 'react'
import Profile from '../components/DashBoard/profile/Profile';

const ProfilePage = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Profile />
    </>
  )
}

export default ProfilePage