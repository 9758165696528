import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Layouts from "../layouts/Layout";
import signup from "../../Asset/images/bg.png";
import Button from "../button/Button";
import "./styles/signup.css";
import Input from "../input/Input";
import userIcon from "../../Asset/Icons/user.svg";
import lock from "../../Asset/Icons/lock.svg";
import { signUp, signIn } from "../../services/auth.service";
import { PhnInput } from "../input/phone-input/PhoneInput";
import { supabase } from "../../services/supabase";
import CheckBox from "../checkBox/CheckBox";
const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  phoneNumber: "",
};

const Signup = () => {
  const history = useNavigate();
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordErr] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [checked, setChecked] = useState(true);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleValidation = (evnt) => {
    setFormData({
      ...formData,
      [evnt.currentTarget.name]: evnt.currentTarget.value,
    });
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const minLengthRegExp = /.{6,}/;
      const passwordLength = passwordInputValue.length;
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 6 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
  };

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handlePhoneNumberChange = (val, data) => {
    const index = val.split(data.dialCode)[1];
    const number = `+${data.dialCode}${parseInt(index, 10)}`;
    setFormData({ ...formData, phoneNumber: number });
  };

  const HandelSubmit = async (e) => {
    e.preventDefault();

    if (
      formData.phoneNumber === "" ||
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.email === "" ||
      formData.password === "" ||
      checked === true
    ) {
      toast.error("Please fill out the form");
    } else {
      const signUpData = {
        email: formData.email,
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        balance: 0,
        profile_url: "",
      };
      const signInData = {
        email: formData.email,
        password: formData.password,
      };
      setLoading(true);
      signUp(signUpData)
        .then((res) => {
          if (res.error != null) {
            toast.error("User already registered, please sign in");
            setLoading(false);
            setFormData({
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              phoneNumber: "",
            });
            setPasswordErr("");
          } else {
            supabase
              .from("users")
              .insert({
                user: res.user.email,
                meta_data: res.user.user_metadata,
                // balance:0,
              })
              .then((response) => {
                if (response.error == null) {
                  signIn(signInData)
                    .then((response) => {
                      toast.success("Registration successful");
                      history("/dashboard");
                      setLoading(false);
                      setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        phoneNumber: "",
                      });
                    })
                    .catch((error) => {
                      toast.error("Could not Register User");
                      setFormData({
                        firstName: "",
                        lastName: "",
                        email: "",
                        password: "",
                        phoneNumber: "",
                      });
                      setLoading(false);
                    });
                }
              })
              .catch((error) => {
                toast.error("An error occured");
              });
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error("Could not Register User");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phoneNumber: "",
          });
          setPasswordErr("");
          setLoading(false);
        });
    }
  };

  return (
    <Layouts img={signup}>
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <h1 className="form-header form-space">Sign Up</h1>
        <Input
          icon={userIcon}
          type="text"
          className="input1"
          placeholder="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleInputChange}
          id="eye-none"
        />
        <Input
          icon={userIcon}
          type="text"
          conClass="input-space"
          className="input1"
          placeholder="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleInputChange}
          id="eye-none"
        />
        <Input
          icon={userIcon}
          type="email"
          conClass="input-space"
          className="input1"
          placeholder="Email Address"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          id="eye-none"
        />
        <PhnInput
          defaultCountry="ng"
          value={formData.phoneNumber}
          name="phoneNumber"
          onChange={handlePhoneNumberChange}
        />

        <Input
          icon={lock}
          type={passwordType}
          className="input1"
          conClass="input-space2"
          placeholder="Password"
          name="password"
          passwordError={passwordError}
          value={formData.password}
          onChange={handleValidation}
          togglePassword={togglePassword}
          passwordType={passwordType}
        />

        <span className="check-box-text">
          <CheckBox
            checked={checked}
            onClick={() => {
              setChecked(!checked);
            }}
          />
          <p>Before creating your account you have to agree with our <b>Terms & Conditions</b></p>
        </span>
        <div className="form-btn-con">
          <Button
            text="Sign up"
            className="colored-btn"
            onClick={HandelSubmit}
            disabled
            loading={loading}
          />
        </div>
      </form>
    </Layouts>
  );
};

export default Signup;
