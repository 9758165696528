import React from 'react'
import RealEstate from '../components/DashBoard/real-estate/RealEstate'

const RealEstatePage = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <RealEstate />
    </>
  )
}

export default RealEstatePage