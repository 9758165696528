import { daysSince, getDueDate } from "./helper";

export const calculation = (data) => {
  if (data.meta_data.type === "transport") {
    const startDate = data?.meta_data?.startDate;
    const [day, month, year] = startDate?.split("/");
     if (data?.meta_data?.ROIPackage === "9% every 3 months") {
      const capital = data.meta_data.amount;
      const roi = capital * 0.09;
      const months = 3;
      const threeMonths = 30 * months;
      if (getDueDate(data?.meta_data?.yearlyDueDate) === true) {
        console.log("Ended");
      } else {
        const differenceInDays = daysSince(new Date(year, month - 1, day));
        if (differenceInDays / threeMonths >= 1) {
          const RoiMul = Math.floor(differenceInDays / threeMonths);
          const daysRemaining = differenceInDays % threeMonths;
          const Roi = roi * RoiMul;
          const id = data.id
          return {id, Roi, daysRemaining, months };
        }
      }
    } else if (data?.meta_data?.ROIPackage === "18% every 6 months") {
      const capital = data.meta_data.amount;
      const roi = capital * 0.18;
      const months = 6;
      const sixMonths = 30 * months;
      if (getDueDate(data?.meta_data?.yearlyDueDate) === true) {
        console.log("Ended");
      } else {
        const differenceInDays = daysSince(new Date(year, month - 1, day));
        if (differenceInDays / sixMonths >= 1) {
          const RoiMul = Math.floor(differenceInDays / sixMonths);
          const daysRemaining = differenceInDays % sixMonths;
          const Roi = roi * RoiMul;
          const id = data.id
          return {id, Roi, daysRemaining, months };
        }
      }
    } else {
      const capital = data?.meta_data?.amount;
      const roi = capital * 0.36;
      const months = 12;
      const oneYear = 30 * months;
      if (getDueDate(data?.meta_data?.yearlyDueDate) === true) {
        console.log("Ended");
      } else {
        const differenceInDays = daysSince(new Date(year, month - 1, day));
        if (differenceInDays / oneYear >= 1) {
          const RoiMul = Math.floor(differenceInDays / oneYear);
          const daysRemaining = differenceInDays % oneYear;
          const Roi = roi * RoiMul;
          const id = data.id
          return {id, Roi, daysRemaining, months };
        }
      }
    }
  }
};
