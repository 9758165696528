import React from 'react'
import { Navigate, Route, Routes} from 'react-router-dom';
// import Loader from '../components/Loader/Loader';
import PageNotFound from '../components/page-not-found/PageNotFound';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import SigninPage from '../pages/SigninPage';
import SignupPage from '../pages/SignupPage';

const UnAuthRoutes = () => {
    return (
        <Routes>
            {/* Register all other non auth routes within this rounte */}
            <Route path="/register" element={<SignupPage />} />
            <Route path="/" element={<Navigate replace to="/register" />} />
            <Route path="/login" element={<SigninPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default UnAuthRoutes