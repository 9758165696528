import React from 'react'
import Nav from '../components/navbar/Navbar'
import Signin from '../components/signin/Signin'

const SigninPage = () => {
  window.scrollTo(0, 0);
  return (
    <>
    <Nav />
    <Signin />
    </>
  )
}

export default SigninPage