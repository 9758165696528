import React from 'react'
import Investment from '../../components/DashBoard/invest/realestate investment/Investment';

const RealestateInvestment = () => {
    window.scrollTo(0, 0);
    return (
        <>
            <Investment />
        </>
    )
}

export default RealestateInvestment