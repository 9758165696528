import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style/modal.css";
import Button from "../button/Button";
import { PaystackConsumer } from "react-paystack";
import { supabase } from "../../services/supabase";
import { useAuth } from "../../providers/auth.provider";
import { toast } from "react-toastify";
import { config } from "../../app.config";

const paystackPublicKey = config.PAYSTACK_PUBLIC_KEY;

const Modal = ({
  setIsOpen,
  modalId1,
  modalId2,
  modalId3,
  investmentAmount,
  invest_data,
  type,
}) => {
  const history = useNavigate();
  const [amount, setAmount] = useState("");
  const [bankloading, setBankLoading] = useState(false);
  const [walletloading, setWalletLoading] = useState(false);
  const [fundloading, setFundLoading] = useState(false);

  const { user } = useAuth();

  const updateAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const config = {
    reference: new Date().getTime().toString(),
    email: `${user?.user?.email}`,
    amount: amount + "00",
    publicKey: `${paystackPublicKey}`,
  };

  const config2 = {
    reference: new Date().getTime().toString(),
    email: `${user?.user?.email}`,
    amount: investmentAmount + "00",
    publicKey: `${paystackPublicKey}`,
  };

  const config3 = {
    reference: new Date().getTime().toString(),
    email: `${user?.user?.email}`,
    amount: investmentAmount + "00",
    publicKey: `${paystackPublicKey}`,
  };

  const handleSuccess = (reference) => {
    if (type === "Transportation") {
      saveToTransport(reference);
    } else saveToRealEstate(reference);
  };

  const handleWalletSuccess = () => {
    if (type === "Transportation") {
      saveTransportbankDetails();
    } else saveEstatebankDetails();
  };

  const handleLoanSuccess = (reference) => {
    saveToLoan(reference);
  };

  const handleLoanWalletSuccess = () => {
    saveLoanbankDetails();
  };

  const handleFundSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    FundAccount(reference);
  };

  // save to db after payment
  const saveToLoan = (payRef) => {
    setBankLoading(true);
    const data = invest_data;

    const loan = {
      name: data.name,
      amount: data.amount,
      email: data.email,
      loanId: data.loanId,
    };
    supabase
      .from("Paid-loans")
      .insert({
        user: data.email,
        loan: loan,
        payment: payRef,
        user_data: data.user.user.user_metadata,
        meta_data: invest_data,
      })
      .then((response) => {
        if (response.error == null) {
          toast.success("Successful");
          supabase
            .from("Loans")
            .delete()
            .match({ id: response.data[0].loan.loanId })
            .then((response) => {
            })
            .catch((error) => {});
          setBankLoading(false);
          // history("/dashboard");
          setIsOpen(false);
        }
      })
      .catch((error) => {
        toast.error("An error occured");
        setBankLoading(false);
        setIsOpen(false);
      });
  };

  const saveLoanbankDetails = () => {
    setWalletLoading(true);
    const data = invest_data;
    const loan = {
      name: data.name,
      amount: data.amount,
      email: data.email,
      loanId: data.loanId,
    };
    if (data.amount > user?.user.user_metadata.balance) {
      toast.error("Insufficent fund, please fund your account");
      history("/dashboard");
      setWalletLoading(false);
      setIsOpen(false);
    } else {
      supabase
        .from("Paid-loans")
        .insert({
          user: data.email,
          loan: loan,
          payment: {},
          user_data: data.user.user.user_metadata,
          meta_data: invest_data,
        })
        .then((response) => {
          const balance =
            user?.user.user_metadata.balance -
            parseInt(response.data[0].loan.amount, 10);
          supabase.auth.update({
            data: {
              balance: balance,
            },
          });
          if (response.error == null) {
            supabase
              .from("Loans")
              .delete()
              .match({ id: response.data[0].loan.loanId })
              .then((response) => {
              })
              .catch((error) => {});
            toast.success("Successful");
            // history("/dashboard");
            setWalletLoading(false);
            setIsOpen(false);
          }
        })
        .catch((error) => {
          toast.error("An error occured");
          setWalletLoading(false);
          setIsOpen(false);
        });
    }
  };

  // save to db after payment
  const saveToRealEstate = (payRef) => {
    setBankLoading(true);
    const data = invest_data;

    const property = {
      name: data.itemName,
      amount: data.amount,
      duration: data.year,
      endDate: data.endDate,
      ROI: data.ROI,
      startDate: data.startDate,
      userROI: data.userROI,
      dueDate: data.dueDate,
    };
    supabase
      .from("investments")
      .insert({
        user: data.email,
        property: property,
        payment: payRef,
        user_data: data.user.user.user_metadata,
        meta_data: invest_data,
      })
      .then((response) => {
        if (response.error == null) {
          toast.success("Successful");
          history("/dashboard");
          setBankLoading(false);
          setIsOpen(false);
        }
      })
      .catch((error) => {
        toast.error("An error occured");
        setBankLoading(false);
        setIsOpen(false);
      });
  };

  // save to db after payment
  const saveToTransport = (payRef) => {
    setBankLoading(true);
    const data = invest_data;
    const transport = {
      amount: data.amount,
      duration: data.year,
      ROI: data.ROI,
      userROI: data.userROI,
      startDate: data.startDate,
      ROIPackage: data.ROIPackage,
      yearlyDueDate: data.yearlyDueDate,
      name: data.itemName,
      endDate: data.endDate,
      dueDate: data.dueDate,
      daysRemaining: data.daysRemaining,
    };
    supabase
      .from("transportation-investment")
      .insert({
        user: data.email,
        transport: transport,
        payment: payRef,
        user_data: data.user.user.user_metadata,
        meta_data: invest_data,
      })
      .then((response) => {
        if (response.error == null) {
          toast.success("Successful");
          history("/dashboard");
          setBankLoading(false);
          setIsOpen(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occured");
        setBankLoading(false);
        setIsOpen(false);
      });
  };

  const saveEstatebankDetails = () => {
    setWalletLoading(true);
    const data = invest_data;
    const property = {
      name: data.itemName,
      amount: data.amount,
      duration: data.year,
      endDate: data.endDate,
      ROI: data.ROI,
      startDate: data.startDate,
      userROI: data.userROI,
      dueDate: data.dueDate,
    };
    if (data.amount > user?.user.user_metadata.balance) {
      toast.error("Insufficent fund, please fund your account");
      setWalletLoading(false);
      setIsOpen(false);
      history("/dashboard");
    } else {
      supabase
        .from("investments")
        .insert({
          user: data.email,
          property: property,
          payment: {},
          user_data: data.user.user.user_metadata,
          meta_data: invest_data,
        })
        .then((response) => {
          const balance =
            user?.user.user_metadata.balance -
            parseInt(response.data[0].property.amount, 10);
          supabase.auth.update({
            data: {
              balance: balance,
            },
          });
          if (response.error == null) {
            toast.success("Successful");
            setWalletLoading(false);
            setIsOpen(false);
            history("/dashboard");
          }
        })
        .catch((error) => {
          toast.error("An error occured");
          setWalletLoading(false);
          setIsOpen(false);
        });
    }
  };

  const saveTransportbankDetails = () => {
    setWalletLoading(true);
    const data = invest_data;
    const transport = {
      amount: data.amount,
      duration: data.year,
      ROI: data.ROI,
      startDate: data.startDate,
      userROI: data.userROI,
      ROIPackage: data.ROIPackage,
      yearlyDueDate: data.yearlyDueDate,
      name: data.itemName,
      dueDate: data.dueDate,
      daysRemaining: data.daysRemaining,
    };
    if (data.amount > user?.user.user_metadata.balance) {
      toast.error("Insufficent fund, please fund your account");
      setWalletLoading(false);
      setIsOpen(false);
      history("/dashboard");
    } else {
      supabase
        .from("transportation-investment")
        .insert({
          user: data.email,
          transport: transport,
          payment: {},
          user_data: data.user.user.user_metadata,
          meta_data: invest_data,
        })
        .then((response) => {
          const balance =
            user?.user.user_metadata.balance -
            parseInt(response.data[0].transport.amount, 10);
          supabase.auth.update({
            data: {
              balance: balance,
            },
          });
          if (response.error == null) {
            toast.success("Successful");
            setWalletLoading(false);
            setIsOpen(false);
            history("/dashboard");
          }
        })
        .catch((error) => {
          toast.error("An error occured");
          setWalletLoading(false);
          setIsOpen(false);
        });
    }
  };

  const FundAccount = (payRef) => {
    setFundLoading(true);
    const balance = {
      amount: amount,
    };
    const meta_data = {
      user,
      amount: amount,
      email: user?.user.user_metadata?.email,
    };
    supabase
      .from("Account Balance")
      .insert({
        user: user?.user.user_metadata?.email,
        balance: balance,
        payment: payRef,
        user_data: user.user.user_metadata,
        meta_data: meta_data,
      })
      .then((response) => {
        toast.success("Successful");
        history("/dashboard");
        setIsOpen(false);
        setFundLoading(false);
        const balance =
          user?.user.user_metadata.balance +
          parseInt(response.data[0].balance.amount, 10);
        supabase.auth.update({
          data: {
            balance: balance,
          },
        });
        supabase
          .from("users")
          .select("*")
          .eq("user", user?.user.user_metadata.email)
          .then((response) => {
            const newMeta = {
              ...response.data[0].meta_data,
              balance: balance,
            };
            const UpdateUserBalance = async () => {
              const { data, error } = await supabase
                .from("users")
                .update({
                  meta_data: newMeta,
                })

                .eq("user", user?.user.user_metadata.email)
                .select();
              if (error != null) {
                console.log(error);
              }
              console.log(data);
            };
            UpdateUserBalance();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        toast.error("An error occured");
        setIsOpen(false)
        setFundLoading(false)
      });
  };

  // you can call this function anything
  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    toast.error("Closed");
  };

  const componentProps = {
    ...config,
    text: "Pay Now",
    onSuccess: (reference) => handleFundSuccess(reference),
    onClose: handleClose,
  };

  const componentProps2 = {
    ...config2,
    text: "Pay from Bank",
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose,
  };

  const componentProps3 = {
    ...config3,
    text: "Pay from Bank",
    onSuccess: (reference) => handleLoanSuccess(reference),
    onClose: handleClose,
  };

  return (
    <div className="modal" onClick={() => setIsOpen(false)}>
      <form
        className=" modal-form"
        onSubmit={handleSubmit}
        id={modalId1}
        onClick={(e) => e.stopPropagation()}
      >
        <p>Minimum amount of NGN1000</p>
        <input
          type="number"
          placeholder="Please enter the amount"
          className="form-control-input"
          amount={amount}
          onChange={updateAmount}
        />

        <div className="invest-form-btn">
          <PaystackConsumer {...componentProps}>
            {({ initializePayment }) => (
              <Button
                onClick={() => {
                  if (amount < 1000) {
                    setIsOpen(false);
                    toast.error("Minimum amount is 1000");
                  } else {
                    initializePayment(handleFundSuccess, handleClose);
                  }
                }}
                loading={fundloading}
                className="colored-btn button"
                text="Pay Now"
              />
            )}
          </PaystackConsumer>
        </div>
      </form>
      <div
        className="modal-form modal-form2"
        id={modalId2}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-form-btn">
          <Button
            text="Pay from Wallet"
            className="colored-btn"
            loading={walletloading}
            onClick={handleWalletSuccess}
          />
          <PaystackConsumer {...componentProps2}>
            {({ initializePayment }) => (
              <Button
                onClick={() => {
                  initializePayment(handleSuccess, handleClose);
                }}
                loading={bankloading}
                className="colored-btn modal-btn"
                text="Pay from Bank"
              />
            )}
          </PaystackConsumer>
        </div>
      </div>
      <div
        className="modal-form modal-form2"
        id={modalId3}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-form-btn">
          <Button
            text="Pay from Wallet"
            className="colored-btn"
            loading={walletloading}
            onClick={handleLoanWalletSuccess}
          />
          <PaystackConsumer {...componentProps3}>
            {({ initializePayment }) => (
              <Button
                onClick={() => {
                  initializePayment(handleLoanSuccess, handleClose);
                }}
                loading={bankloading}
                className="colored-btn modal-btn"
                text="Pay from Bank"
              />
            )}
          </PaystackConsumer>
        </div>
      </div>
    </div>
  );
};

export default Modal;
