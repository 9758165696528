import React, { useContext, useEffect, useReducer} from "react";
import reducer from "../reducer/product_reducer";
import { GET_PRODUCTS_SUCCESS, GET_TRANSPORT_SUCCESS } from "../action";
import { supabase } from "../../services/supabase";

const initialState = {
  products: [],
  transports: [],
  featured_products: [],
};

const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fetchProducts = () => {
    supabase
      .from("Admin RealEstate")
      .select("*")
      .then(response => {
        const product = response.data;
        dispatch({ type: GET_PRODUCTS_SUCCESS, payload: product });
      })
      .catch(error => {
      })

  };

  const fetchTransport = () => {
    supabase
      .from("Admin Transport")
      .select("*")
      .then(response => {
        const transport = response.data;
        dispatch({ type: GET_TRANSPORT_SUCCESS, payload: transport });
        
      })
      .catch(error => {
      })

  };

  useEffect(() => {
    fetchProducts();
    fetchTransport();
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        fetchProducts,
        fetchTransport
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
// make sure use
export const useProductsContext = () => {
  return useContext(ProductsContext);
};
