import React, { useState } from 'react'
import Button from '../button/Button';
import { Spinner } from "../spinner/Spinner";
import { toast } from 'react-toastify';
import { forgetPassword } from '../../services/auth.service';

const ForgetModal = ({ setIsOpen }) => {

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        forgetPassword(email)
            .then((res) => {
                setLoading(false)
                console.log(res)
                if (res.error != null) {
                  toast.error("Invalid email ")
                  setLoading(false)
                }
                else {
                  toast.success("Reset email sent")
                  setLoading(false)
                  setIsOpen(false)
                }
            })
            .catch(error => {
                toast.error('An error occured')
                setLoading(false)
            })
    }

    return (
        <div className='modal' onClick={() => setIsOpen(false)}>
            <form className="modal-form" id="forget-modal-form" onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
                <input type="email" placeholder="Email" className="form-control-input" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <div className="invest-form-btn">
                    {loading ? <Spinner /> : <Button className="colored-btn" text="Submit" />}
                </div>
            </form>
        </div>
    )
}

export default ForgetModal