import React from "react";
import { Button, Result } from "antd";
import "./AllItems.css";
import Layout from "../image layout/Layout";

const AllItems = ({ items, admin }) => {
  if (items.length < 1) {
    return (
      <div className="all-results">
        <Result
          status="404"
          title="Item Not Found"
          extra={
            <a href="/RealEstate">
              <Button text="See all Real Estate" className="btn colored-btn form-btn-space"/>
            </a>
          }
        />
      </div>
    );
  }
  return (
    <div className="allItems-grid">
      {items?.map((item) => {
        const { id } = item;
        return <Layout key={id} {...item} id1="no-display" id2={admin? 'no-display': 'display'} id3={admin? 'display' : 'no-display'} />;
      })}
    </div>
  );
};

export default AllItems;
