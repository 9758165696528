import React from 'react'
import Home from '../../components/Admin Dashboard/Home/Home';

const HomePage = () => {
    window.scrollTo(0, 0);
  return (
    <>
      <Home />
    </>
  )
}

export default HomePage