import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../button/Button";
import { DropdownInput } from "../../input/dropdown-input-v2/DropdownInput";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { supabase } from "../../../services/supabase";
import { useAuth } from "../../../providers/auth.provider";
import { bankOption } from "../../options";
import { toast } from "react-toastify";
import { config } from "../../../app.config";
import ProfileImage from "../../profile-img/Layout";
import {
  getFullDateInDays,
  getFullDateInMonth2,
  getDueDate,
} from "../../utilities/helper";
import { Empty } from "antd";
import { calculation } from "../../utilities/transportRoiCalculation";

const WithdrawROI = () => {
  const { user } = useAuth();
  const initialFormData = {
    withdrawROI: 0,
    withdrawalDetail: [],
    accountNumber: user?.user.user_metadata?.accountNumber
      ? `${user?.user.user_metadata?.accountNumber}`
      : "",
  };
  const { Option } = Select;
  const [allrealEstateArray, setallrealEstateArray] = useState([]);
  const [alltransportArray, setalltransportArray] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const history = useNavigate();
  const [accName, setAccName] = useState(
    user?.user.user_metadata?.accountName
      ? `${user?.user.user_metadata?.accountName}`
      : ""
  );
  const [loading, setLoading] = useState(false);
  const [bank, setBank] = useState(
    user?.user.user_metadata?.bank ? `${user?.user.user_metadata?.bank}` : ""
  );

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const getAccountName = async (acc_number, bank_code) => {
    const url = `https://api.paystack.co/bank/resolve?account_number=${acc_number}&bank_code=${bank_code}`;
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${config.PAYSTACK_SECRET_KEY}`,
        },
      })
      .then((response) => {
        setAccName(response.data.data.account_name);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Couldn't get account name");
      });
  };

  const handleChange = (value, key) => {
    setBank(value);
    console.log(key);

    getAccountName(formData.accountNumber, key.id);
  };

  const handleChangewithdrawalDetails = (value, key) => {
    const array = value;
    let sum = 0;

    for (let i = 0; i < array.length; i++) {
      const p = array[i];
      const q = p.split("_").slice(1);
      sum += +q;
    }
    setFormData({ ...formData, withdrawROI: sum, withdrawalDetail: key });
  };

  const getAllInvestments = () => {
    supabase
      .from("investments")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        setallrealEstateArray(response.data);
      })
      .catch((error) => {});
    supabase
      .from("transportation-investment")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        setalltransportArray(response.data);
      })
      .catch((error) => {});
  };

  const realEstateArray = allrealEstateArray;
  const transportArray = alltransportArray;
  const investmentArray = realEstateArray.concat(transportArray);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    if (formData.withdrawROI === 0 || bank === '' || formData.accountNumber === "" || accName === '') {
      toast.error("Please fill out the form");
      setLoading(false);
    } else {
      const meta_data = {
        withdrawROI: formData.withdrawROI,
        withdrawalDetail: formData.withdrawalDetail,
        bank: bank,
        accountNumber: formData.accountNumber,
        accountName: accName,
        email: user?.user.user_metadata?.email,
        user,
      };

      const withdrawal = {
        withdrawalDetail: formData.withdrawalDetail,
        withdrawROI: formData.withdrawROI,
      };

      const bank_details = {
        bank: bank,
        accountNumber: formData.accountNumber,
        accountName: accName,
      };

      supabase
        .from("withdraw roi")
        .insert({
          user: user?.user.user_metadata?.email,
          withdrawal: withdrawal,
          bank_details: bank_details,
          user_data: user?.user.user_metadata,
          meta_data: meta_data,
        })
        .then((response) => {
          supabase.auth.update({
            data: {
              bank: response.data[0].bank_details.bank,
              accountName: response.data[0].bank_details.accountName,
              accountNumber: response.data[0].bank_details.accountNumber,
            },
          });
          if (response.error == null) {
            response.data[0].withdrawal.withdrawalDetail.map((details) => {
              if (details.id.meta_data.type === "real-estate") {
                supabase
                  .from("investments")
                  .delete()
                  .match({ id: details.id.id })
                  .then((response) => {
                    console.log("this is the delete response", response);
                  })
                  .catch((error) => {});
              }
              if (details.id.meta_data.type === "transport") {
                if (
                  getDueDate(details.id.meta_data.yearlyDueDate) === true
                ) {
                  supabase
                    .from("transportation-investment")
                    .delete()
                    .match({ id: details.id.id })
                    .then((response) => {
                      console.log("this is the delete response", response);
                    })
                    .catch((error) => {});
                } else {
                  if (calculation(details.id) !== undefined) {
                    let my_object = {};
                    my_object.id = calculation(details.id).id;
                    my_object.daysRemaining = calculation(
                      details.id
                    ).daysRemaining;
                    my_object.months = calculation(details.id).months;
                    const newMeta = {
                      ...details.id.meta_data,
                      dueDate: getFullDateInMonth2(
                        my_object.months,
                        my_object.daysRemaining
                      ),
                      startDate: getFullDateInDays(my_object.daysRemaining),
                      userROI: 0,
                    };

                    const newTransportData = {
                      ...details.id.transport,
                      userROI: 0,
                      dueDate: getFullDateInMonth2(
                        my_object.months,
                        my_object.daysRemaining
                      ),
                      startDate: getFullDateInDays(my_object.daysRemaining),
                    };
                    const UpdateUserData = async () => {
                        console.log('newT', newTransportData)
                        console.log('newM', newMeta)
                      await supabase
                        .from("transportation-investment")
                        .update({
                          meta_data: newMeta,
                        })
                        .eq("id", my_object.id )
                        .select();

                      await supabase
                        .from("transportation-investment")
                        .update({
                          transport: newTransportData,
                        })
                        .eq("id", my_object.id )
                        .select();
                    };
                    UpdateUserData();
                  }
                }
              }
              return null;
            });
            toast.success("Successful");
            history("/dashboard");
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error("An error occured");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getAllInvestments();

    // eslint-disable-next-line
  }, []);

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="get-loan-right-main">
          <div className="dash-header-main-con">
            <p className="get-loan-header-text">Withdraw ROI</p>
            <div className="bell-profile">
              <Link to="/dashboard" className="view-dashboard">
                <p>View dashboard</p>
              </Link>
              <ProfileImage img={user?.user.user_metadata?.profile_url} />
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>

          <form className="get-loan-form-layout" onSubmit={handleSubmit}>
            <div className="input-container">
              <Select
                mode="multiple"
                allowClear
                className="investment-select-input"
                placeholder="Please select"
                onChange={handleChangewithdrawalDetails}
                notFoundContent={
                  <Empty
                    description={
                      <p style={{ color: "red", fontSize: "18px" }}>
                        No ROI is due
                      </p>
                    }
                  />
                }
              >
                {investmentArray.map((investment) => {
                  if (
                    getDueDate(investment.meta_data.dueDate) === true
                  ) {
                    return (
                      <Option
                        key={investment.id}
                        value={`${investment.id}_${investment.meta_data.userROI}`}
                        id={investment}
                      >
                        {investment.meta_data.itemName}
                      </Option>
                    );
                  }
                  return null;
                })}
              </Select>
            </div>
            <p className="get-loan-form-text">Recieving account details</p>
            <input
              type="number"
              placeholder="Account Number"
              className="form-control-input2"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleInputChange}
            />
            <DropdownInput
              placeHolder="Select bank"
              defaultValue={
                user?.user.user_metadata?.bank
                  ? `${user?.user.user_metadata?.bank}`
                  : "Select bank"
              }
              onChange={handleChange}
              options={bankOption}
            />

            <input
              type="text"
              value={accName}
              disabled
              className="form-control-input"
            />
            <div className="form-btn-con">
              <Button
                text="Submit"
                className="colored-btn form-control-btn"
                loading={loading}
              />
            </div>
          </form>
        </section>
      </div>
    </div>
  );
};

export default WithdrawROI;
