import React from 'react'
import Nav from '../components/navbar/Navbar'
import Signup from '../components/signup/Signup'

const SignupPage = () => {
  window.scrollTo(0, 0);
  return (
    <>
    <Nav />
    <Signup />
    </>
  )
}

export default SignupPage