import React from 'react'
import {
    FaBusAlt
} from "react-icons/fa";
import { BsFillHouseFill } from "react-icons/bs"
import "./style/invest.css"
import { useNavigate } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { useAuth } from '../../../providers/auth.provider';
import ProfileImage from '../../profile-img/Layout';

const Invest = () => {
    const { user } = useAuth();
    const history = useNavigate();
    const handleMenuClick = (e) => {
        if (e.key === "1") {
            history("/RealEstate");
        }
        if (e.key === "2") {
            history("/transport");
        }
    };

    const menu = (
        <Menu
            onClick={handleMenuClick}
            items={[
                {
                    label: "RealEstate",

                    key: "1",
                    icon: <BsFillHouseFill />,
                },
                {
                    label: "Transport",
                    key: "2",
                    icon: <FaBusAlt />,
                },
            ]}
        />
    );

    return (
        <div id="dash-main-con">
            <div className='get-loan-right'>
                <section className='invest-main-con'>
                    <div className="dash-header-main-con">
                        <p className="invest-header-text">Invest</p>
                        <div className="bell-profile">
                            <ProfileImage img={user?.user.user_metadata?.profile_url} />
                            <div>
                                <FaBell className="bell-con" />
                            </div>
                        </div>
                    </div>
                    <div className="investment-dropdown-con">
                        <Dropdown overlay={menu} className="investment-dropdown">
                            <Space>
                                What would you like to invest in?
                                <DownOutlined />
                            </Space>

                        </Dropdown>
                    </div>

                </section>
            </div>
        </div>
    )
}

export default Invest