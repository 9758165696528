import React from 'react'
import WithdrawROI from '../components/DashBoard/withdraw Roi/WithdrawROI';

const WithdrawRoiPage = () => {
    window.scrollTo(0, 0);
  return (
   <>
   <WithdrawROI />
   </>
  )
}

export default WithdrawRoiPage