import React from "react";
import { Result } from "antd";
import "../AllItems.css";
import Layout from "../../DashBoard/transport/transport-layout-con/Layout";

const AllItems = ({ items, admin }) => {
  if (items?.length < 1) {
    return (
      <div className="all-results">
        <Result
          status="404"
          title="No transport Found"
        />
      </div>
    );
  }
  return (
    <div className="allItems-grid">
      {items.map((item) => {
        const { id } = item;
        return <Layout key={id} {...item} admin={admin}/>;
      })}
    </div>
  );
};

export default AllItems;
