import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardNav from "../components/DashBoard/Dashboard-nav/DashboardNav";
import PageNotFound from "../components/page-not-found/PageNotFound";
import AddInvestmentPage from "../pages/Admin/AddInvestmentPage";
import AllUserPage from "../pages/Admin/AllUserPage";
import HomePage from "../pages/Admin/HomePage";
import InvestmentPage from "../pages/Admin/InvestmentPage";
import SingleUserPage from "../pages/Admin/SingleUserPage";
import AdminTransportPage from "../pages/Admin/TransportPage";
import AllIncomePage from "../pages/AllIncomePage";
import AllLoanPage from "../pages/AllLoanPage";
import DashboardPage from "../pages/DashboardPage";
import GetLoanPage from "../pages/GetLoanPage";
import RealestateInvestment from "../pages/investment/RealestateInvestment";
import TransportInvestment from "../pages/investment/TransportInvestment";
import InvestPage from "../pages/InvestPage";
import ProfilePage from "../pages/ProfilePage";
import RealEstatePage from "../pages/RealEstatePage";
import TransportPage from "../pages/TransportPage";
import WithdrawRoiPage from "../pages/WithdrawRoiPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";

const AuthRoutes = () => {
  return (
    <>
      {/* <DashboardNav> */}
      <Routes>
        <Route path="/" element={<DashboardNav />}>
          <Route path="/admin" element={<HomePage />} />
          <Route path="/admin-realEstate" element={<InvestmentPage />} />
          <Route path="/admin-transport" element={<AdminTransportPage />} />
          <Route path="/admin-add" element={<AddInvestmentPage />} />
          <Route path="/admin-users" element={<AllUserPage />} />
          <Route path="/admin-users/:id" element={<SingleUserPage />} />
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/dashboard/investments" element={<AllIncomePage />} />
          <Route
            path="/admin/investments"
            element={<AllIncomePage admin={true} />}
          />
          <Route
            path="/admin/investments/:email"
            element={<AllIncomePage email={true} />}
          />
          <Route path="/dashboard/withdrawROI" element={<WithdrawRoiPage />} />
          <Route path="/dashboard/loan" element={<AllLoanPage />} />
          <Route path="/admin/loan" element={<AllLoanPage admin={true} />} />
          <Route
            path="/admin/loan/:email"
            element={<AllLoanPage email={true} />}
          />
          <Route
            path="/register"
            element={<Navigate replace to="/dashboard" />}
          />
          <Route path="/RealEstate" element={<RealEstatePage />} />
          <Route path="/transport" element={<TransportPage />} />
          <Route path="/Getloan" element={<GetLoanPage />} />
          <Route path="/invest" element={<InvestPage />} />
          <Route
            path="/invest/realestate/:id"
            element={<RealestateInvestment />}
          />
          <Route
            path="/invest/transportation/:id"
            element={<TransportInvestment />}
          />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>

        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* </DashboardNav> */}
    </>
  );
};

export default AuthRoutes;
