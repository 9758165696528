import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';

import Layouts from "../layouts/Layout";
import signup from "../../Asset/images/bg.png";
import Button from "../button/Button";
import Input from "../input/Input";
import userIcon from "../../Asset/Icons/user.svg";
import lock from "../../Asset/Icons/lock.svg";
import { signIn } from '../../services/auth.service';

import "./styles/signin.css"
import ForgetModal from "../modal/ForgetModal";

const initialFormData = {
  email: '',
  password: '',
};

const Signin = () => {

  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.currentTarget.name]: event.currentTarget.value });
  };

  const HandelSubmit = async (e) => {
    e.preventDefault();
    const signInData = {
      email: formData.email,
      password: formData.password,
    }
    setLoading(true);
    signIn(signInData)
      .then((res) => {
        if (res.error != null) {
          toast.error("Invalid login credentials")
          setLoading(false)
        }
        else {
          if (res.user.user_metadata?.role === 'ADMIN') {
            toast.success("Login Successful")
            history('/admin');
            setLoading(false)
          } else {
            toast.success("Login Successful")
            history('/dashboard');
            setLoading(false)
          }
        }
      })
      .catch(error => {
        toast.error('Could not login in User')
        setLoading(false)
      })
  }

  return (
    <Layouts img={signup}>
      <div className="form-main-con">
        <form className="form" onSubmit={(e) => { e.preventDefault() }}>
          <h1 className="form-header form-space">Sign In</h1>
          <Input
            icon={userIcon}
            type="email"
            conClass="input-space"
            className="input1"
            placeholder="Email Address"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            id="eye-none"
          />
          <Input
            icon={lock}
            type={passwordType}
            className="input1"
            conClass="input-space"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            togglePassword={togglePassword}
            passwordType={passwordType}
            id="eye-block"
          />

          <div className="form-btn-con"><Button text="Sign in" className="colored-btn" onClick={HandelSubmit} loading={loading}/></div>
          
          <div className='sign-up-forget-con'>
            <div className='no-account-con'><p className="no-account-text">Do not have an Account?<Link to="/register" className="no-account-btn">Sign up</Link> </p> </div>
            <div onClick={() => setIsOpen(true)}><p className="no-account-text forget-text">Forgot Password?</p></div>
          </div>
        </form>
        {isOpen && <ForgetModal setIsOpen={setIsOpen} />}
      </div>
    </Layouts>
  )
}

export default Signin