import React from 'react'
import AllLoan from '../components/DashBoard/account statment/view-all-loan/AllLoan';

const AllLoanPage = ({admin, email}) => {
    window.scrollTo(0, 0);
    return (
        <>
            <AllLoan admin={admin} userEmail={email}/>
        </>
    )
}

export default AllLoanPage