import React, { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Upload } from "antd";
import "./styles/profile.css";
import Button from "../../button/Button";
import Footer from "../../Footer/Footer";
import Moment from "moment";
import { useAuth } from "../../../providers/auth.provider";
import { supabase } from "../../../services/supabase";
import { AiFillCamera } from "react-icons/ai";
import { toast } from "react-toastify";
import { randomTextGenerator } from "../../utilities/randomtextgenerator";
import { Spinner } from "../../spinner/Spinner";

const Profile = () => {
  const [activeSection, setActiveSection] = useState(0);
  const [uploadList, setUploadList] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const sections = ["User Info", "Account Details"];
  const changeActiveSection = (index) => {
    setActiveSection(index);
  };

  const { user } = useAuth();

  const date = user?.user.created_at;

  const formatDate = Moment(date).format("LL");

  const onChangeUpload = (e) => {
    setFileList(e.file.originFileObj);
    setUploadList(true);
  };

  const handleUpload = async (e) => {
    if (fileList.length === 0) {
      toast.error("Please select an image");
    } else {
      setLoading(true);
      await supabase.storage
        .from("investment")
        .upload(`${randomTextGenerator()}_${fileList.name}`, fileList, {
          cacheControl: "3600",
          upsert: false,
        })
        .then((res) => {
          if (res.data?.Key !== null) {
            const imageKey = `https://ogvijkbdzdnrblbxfxfz.supabase.co/storage/v1/object/public/${res.data?.Key}`;
            supabase.auth.update({
              data: {
                profile_url: imageKey,
              },
            });
            setUploadList(false);
            setLoading(false);
            toast.success("upload successfully.");
            setFileList([])
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
          setFileList([])
        });
    }
  };

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="profile-main-con">
          <p className="profile-header-text">Profile</p>
          <div className="profile-dash-container">
            <div className="profile-img-con">
              <p className="profile-img-name">
                {user?.user.user_metadata?.firstName}{" "}
                {user?.user.user_metadata?.lastName}
              </p>
              <Upload onChange={onChangeUpload} showUploadList={uploadList}>
                <div className="cam-ava-con">
                  <div className="camera-con">
                    <AiFillCamera className="camera-icon" />
                  </div>
                  {user?.user.user_metadata?.profile_url === "" ? (
                    <Avatar
                      icon={<UserOutlined className="avatar-icon" />}
                      className="avatar"
                    />
                  ) : (
                    <div className="profile-pic-con">
                      <img
                        src={user?.user.user_metadata?.profile_url}
                        alt="profile"
                        className="profile-img"
                      />
                    </div>
                  )}
                </div>
              </Upload>
              <div className="form-btn-con">
                {loading ? (
                  <Spinner />
                ) : (
                  <Button
                    text="Upload New Photo"
                    className="colored-btn"
                    onClick={handleUpload}
                  />
                )}
              </div>

              <p className="profile-img-mem-text">
                Member since:{" "}
                <span className="profile-img-mem-date"> {formatDate}</span>
              </p>
            </div>
            <div className="profile-edit-profile-con">
              <p className="edit-pro-text">Edit Profile</p>
              <div className="profile-link-con">
                {sections.map((section, index) => (
                  <p
                    key={index}
                    index={index}
                    onClick={() => changeActiveSection(index)}
                    className={`profile-link ${
                      activeSection === index ? "profile-link-select" : ""
                    }`}
                  >
                    {section}
                  </p>
                ))}
              </div>

              <div className="profile-info-con">
                {activeSection === 0 ? (
                  <form>
                    <div className="profile-info-form-layout-con">
                      <div className="profile-info-form-layout">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          name="first_name"
                          disabled
                          value={`${user?.user.user_metadata?.firstName}`}
                        />
                      </div>
                      <div className="profile-info-form-layout">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          name="last_name"
                          disabled
                          value={`${user?.user.user_metadata?.lastName}`}
                        />
                      </div>
                    </div>
                    <div className="profile-info-form-layout-con">
                      <div className="profile-info-form-layout">
                        <label htmlFor="email_address">Email Address</label>
                        <input
                          type="text"
                          name="email_address"
                          disabled
                          value={`${user?.user.user_metadata?.email}`}
                        />
                      </div>
                      <div className="profile-info-form-layout">
                        <label htmlFor="confirm_email_address">
                          Confirm Email Address
                        </label>
                        <input
                          type="text"
                          name="confirm_email_address"
                          disabled
                          value={`${user?.user.user_metadata?.email}`}
                        />
                      </div>
                    </div>
                    <div className="profile-info-form-layout-con">
                      <div className="profile-info-form-layout">
                        <label htmlFor="phone number">Phone Number</label>
                        <input
                          type="text"
                          name="first_name"
                          disabled
                          value={`${user?.user.user_metadata?.phoneNumber}`}
                        />
                      </div>
                      <div className="profile-info-form-layout">
                        <label htmlFor="password">Password</label>
                        <input
                          type="password"
                          name="password"
                          disabled
                          value={`${user?.user.user_metadata?.password}`}
                        />
                      </div>
                    </div>
                    <Button text="Update Profile" className="disabled-btn" />
                  </form>
                ) : (
                  <form>
                    <div className="profile-info-form-layout-con">
                      <div className="profile-info-form-layout">
                        <label htmlFor="account_name">Account Name</label>
                        <input
                          type="text"
                          name="account_name"
                          disabled
                          value={
                            user?.user.user_metadata?.accountName
                              ? `${user?.user.user_metadata?.accountName}`
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="profile-info-form-layout-con">
                      <div className="profile-info-form-layout">
                        <label htmlFor="account_number">Account Number</label>
                        <input
                          type="text"
                          name="account_number"
                          disabled
                          value={
                            user?.user.user_metadata?.accountNumber
                              ? `${user?.user.user_metadata?.accountNumber}`
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="profile-info-form-layout-con">
                      <div className="profile-info-form-layout">
                        <label htmlFor="bank_name">Bank Name</label>
                        <input
                          type="text"
                          name="bank_name"
                          disabled
                          value={
                            user?.user.user_metadata?.bank
                              ? `${user?.user.user_metadata?.bank}`
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <Button text="Update Profile" className="disabled-btn" />
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
        <div className="dash-footer-con">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Profile;
