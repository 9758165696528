import React, { useEffect, useState } from "react";
import Button from "../../button/Button";
import { toast } from "react-toastify";
import { FileInput } from "../../input/file-input/FileInput";
import { supabase } from "../../../services/supabase";
import { useAuth } from "../../../providers/auth.provider";
import { randomTextGenerator } from "../../utilities/randomtextgenerator";

const initialFormData = {
  name: "",
  amount: "",
};

const AddTransport = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [fileList, setFileList] = useState([]);
  const [fileKey, setFileKey] = useState("");
  const [uploading, setUploading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const onChangeUpload = (e) => {
    setFileList(e.target.files[0]);
  };

  const handleUpload = async () => {
    setUploading(true);
    await supabase.storage
      .from("investment")
      .upload(`${randomTextGenerator()}_${fileList.name}`, fileList, {
        cacheControl: "3600",
        upsert: false,
      })
      .then((res) => {
        setFileKey(res.data?.Key);
        toast.success("uploaded Successful");
        setUploading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const transport = {
      name: formData.name,
      amount: formData.amount,
      imageKey: `https://ogvijkbdzdnrblbxfxfz.supabase.co/storage/v1/object/public/${fileKey}`,
    };
    supabase
      .from("Admin Transport")
      .insert({
        user: user.user.email,
        transport: transport,
        user_data: user.user.user_metadata,
      })
      .then((response) => {
        if (response.error == null) {
          toast.success("Form Submitted Successful");
          setFormData({
            name: "",
            amount: "",
          });
          setFileList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error("An error occured");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (fileList.length !== 0) {
      handleUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (
      uploading === true ||
      fileList.length === 0 ||
      formData.name === "" ||
      formData.amount === 0 ||
      formData.location === "" ||
      fileKey === undefined
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    fileKey,
    fileList.length,
    formData.amount,
    formData.location,
    formData.name,
    uploading,
  ]);

  return (
    <form className="get-loan-form-layout" onSubmit={(e) => e.preventDefault()}>
      <input
        type="text"
        className="form-control-input"
        required
        placeholder="Enter the name"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
      />
      <input
        type="number"
        className="form-control-input"
        required
        placeholder="Enter the amount"
        name="amount"
        value={formData.amount}
        onChange={handleInputChange}
      />
      <div>
        <FileInput onChange={onChangeUpload} uploading={uploading} />
        {fileList !== [] ? <p className="file-selected-name">{fileList.name}</p> : null}
      </div>
      {disabled ? (
        <Button text="Submit" className="disabled-btn form-control-btn" />
      ) : (
        <div className="form-btn-con">
          <Button
            text="Submit"
            className="colored-btn form-control-btn"
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      )}
    </form>
  );
};

export default AddTransport;
