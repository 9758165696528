import numeral from "numeral";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase } from "../../../../services/supabase";
import Button from "../../../button/Button";
import { useProductsContext } from "../../../Context/product_context";

const Layout = ({ id, transport, admin }) => {
  const { fetchTransport } = useProductsContext();

  const deleteTransport = () => {
    supabase
      .from("Admin Transport")
      .delete()
      .match({ id: id })
      .then((response) => {
        toast.success("Deleted Successfully");
        fetchTransport();
      })
      .catch((error) => {});
  };

  return (
    <div className="image-des-con">
      <div className="img-con">
        {" "}
        <img src={transport?.imageKey} alt="#" className="img" />
      </div>
      <div className="house-des">
        <p className="house-price">
          {" "}
          NGN {`${numeral(transport?.amount).format("0,0")}`}
        </p>

        <div id={admin ? "display" : "no-display"}>
          <Button
            text="Delete"
            className="colored-btn"
            onClick={deleteTransport}
          />
        </div>
        <Link
          to={`/invest/transportation/${id}`}
          id={admin ? "no-display" : "display"}
        >
          <Button text="Invest Now" className="colored-btn" />
        </Link>
      </div>
    </div>
  );
};

export default Layout;
