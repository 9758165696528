import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import './styles/phone-input.css';


export const PhnInput = ({ value, name, onChange, defaultCountry, phoneInputClassName, className }) => {
  return (
    <div className={`phn-input-container ${phoneInputClassName}`}>
      <PhoneInput
        country={defaultCountry}
        placeholder=""
        value={value}
        onChange={onChange}
        enableSearch
        countryCodeEditable={false}
        inputClass={`phone-input ${className}`}
        inputStyle={{ paddingLeft: '50', width: '100%', height: '50px', outline: 'none', border: '2px solid lightgrey', borderRadius: '10px', }}
        inputProps={{ name }}
      />
    </div>
  );
};
