import React from 'react'
import { Link } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from "antd"
import "./style/profile-img.css"

const ProfileImage = ({ img }) => {

    return (
        <>
            {img ===  ""? <Link to="/profile"><div className='person-icon'><Avatar icon={<UserOutlined  />} className="p-layout"/></div></Link> : <Link to="/profile"><div className='person-icon'><img src={img} alt="#" /></div></Link>}
        </>
    )
}

export default ProfileImage