import React from "react";
import Layout from "../image layout/Layout";
import { toast } from 'react-toastify';
import "../layouts/styles/layout.css";
import { useProductsContext } from "../Context/product_context";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../Footer/Footer";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  LargeDesktop: {
    breakpoint: { max: 3000, min: 1500 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1500, min: 1200 },
    items: 3
  },
  smallDesktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 992, min: 700 },
    items:2 
  },
  smallTablet: {
    breakpoint: { max: 700, min: 500 },
    items: 1.5
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1
  }
};

const Layouts = ({ img, children }) => {
  const { products } = useProductsContext();

  const openNotification = () => {
    window.scrollTo(0, 0);
    toast.error('Please login')
  };
  return (
    <div>
      <div className="container">
        <div className="layout-main-con">
          <div className="layout-left">{children}</div>
          <div className="layout-right">
            <img src={img} alt="#" className="registration-img" />
          </div>
        </div>
        <div className="carousel-con">
          <Carousel
            responsive={responsive}
          >
            {products?.slice(0, 6)?.map((product) => {
              return <Layout key={product.id} {...product} id2="no-display" id1="display" id3="no-display" onClick={() => openNotification()} />;
            })}
          </Carousel>
        </div>
      </div>
      <div className="dash-footer-con">

        <Footer />
      </div>
    </div>
  );
};

export default Layouts;
