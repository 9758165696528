import React, { useState } from "react";
import "./styles/dash-acc.css";
import numeral from "numeral";

import cash from "../../../Asset/Icons/cash2.svg";
import { Spinner } from "../../spinner/Spinner";
import { Link } from "react-router-dom";
import Button from "../../button/Button";
import Modal from "../../modal/Modal";
import { useAuth } from "../../../providers/auth.provider";
import { getDueDate } from "../../utilities/helper";

const DashLoanStatement = (props) => {
  const { options, text, loading, admin, email } = props;
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div id="dash-account-statement-con">
        <div className="dash-account-statement-all">
          <p className="dash-account-tran">{`Transactions | ${text}`}</p>
          {admin === true ? (
            <Link to="/admin/loan" className="dash-account-statement-all-link">
              view all
            </Link>
          ) : email ? (
            <Link
              to={`/admin/loan/${email}`}
              className="dash-account-statement-all-link"
            >
              view all
            </Link>
          ) : (
            <Link
              to="/dashboard/loan"
              className="dash-account-statement-all-link"
            >
              view all
            </Link>
          )}
        </div>
        {loading === false ? (
          <div id="dash-account-statement-slide-con">
            {" "}
            <div className="dash-account-user-container">
              {options?.slice(0, 6).map((opt) => {
                const loanPaymentObj = {
                  amount: opt.payment.amount,
                  name:
                    user?.user.user_metadata?.firstName +
                    " " +
                    user?.user.user_metadata?.lastName,
                  email: user?.user.user_metadata?.email,
                  user,
                  loanId: opt.id,
                };
                return (
                  <div className="dash-account-statement" key={opt.id}>
                    <div className="dash-account-img-con">
                      <img src={cash} alt="#" />
                      <p className="dash-account-des">Outstanding loan</p>
                    </div>
                    <p className="dash-account-amount">
                      NGN {numeral(opt.payment.amount).format("0,0")}
                    </p>
                    <p className="dash-account-date">
                      {admin === true || email ? (
                        <>Due Date: {opt.meta_data.dueDate}</>
                      ) : (
                        <>
                          {getDueDate(opt?.meta_data?.dueDate) ===true ? (
                            <Button
                              text="Pay Now"
                              className="colored-btn "
                              onClick={() => setIsOpen(true)}
                            />
                          ) : (
                        <>Due Date: {opt.meta_data.dueDate}</>
                        )}
                        </>
                      )}
                    </p>
                    {isOpen && (
                      <Modal
                        setIsOpen={setIsOpen}
                        modalId3="modal-block"
                        modalId1="modal-none"
                        modalId2="modal-none"
                        investmentAmount={opt.payment.amount}
                        invest_data={loanPaymentObj}
                      />
                    )}
                  </div>
                );
              })}
            </div>{" "}
          </div>
        ) : (
          <div className="loading-spinner-con">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default DashLoanStatement;
