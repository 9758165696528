import React, { useEffect, useState } from "react";
import Button from "../../../button/Button";
import { DropdownInput } from "../../../input/dropdown-input-v2/DropdownInput";
import "../style/invest.css";
import ProfileImage from "../../../profile-img/Layout";
import { useParams } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import Modal from "../../../modal/Modal";
import Footer from "../../../Footer/Footer";
import { useAuth } from "../../../../providers/auth.provider";
import { ROIOption, transportDurationOption } from "../../../options";
import numeral from "numeral";
import { toast } from "react-toastify";
import { useProductsContext } from "../../../Context/product_context";
import {
  getFullDateInMonth,
  getFullDateInYears,
} from "../../../utilities/helper";

const Investment = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const { transports } = useProductsContext();
  const [product, setProduct] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [year, setYear] = useState(0);
  const [ROI, setROI] = useState(0);
  const [userROI, setUserROI] = useState(0);
  const [ROIPackage, setROIPackage] = useState("");
  const [yearlyDueDate, setYearlyDueDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(0);
  let daysRemaining = 0;
  const transportObj = {
    amount: product?.transport?.amount,
    year,
    ROI,
    ROIPackage,
    userROI,
    yearlyDueDate,
    dueDate,
    endDate,
    startDate,
    daysRemaining,
    name:
      user?.user.user_metadata?.firstName +
      " " +
      user?.user.user_metadata?.lastName,
    email: user?.user.user_metadata?.email,
    itemName: product?.transport?.name,
    type: "transport",
    user,
  };

  useEffect(() => {
    setProduct(transports?.find((product) => String(product.id) === id));
  }, [id, transports]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const onChange = (e) => {
    setROIPackage(e);
  };
  const yearsOnChange = (value) => {
    setYear(value);
    setEndDate(Number(value) * 365);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calROI = (amount) => {
    let mainROI;

    if (ROIPackage.toString().includes("9%")) {
      let _roi = Number(amount) * 0.09;
      mainROI = Number(amount) + _roi;
      setROI(mainROI);
      setUserROI(0);
    } else if (ROIPackage.toString().includes("18%")) {
      let _roi = Number(amount) * 0.18;
      mainROI = Number(amount) + _roi;
      setROI(mainROI);
      setUserROI(0);
    } else if (ROIPackage.toString().includes("36%")) {
      let _roi = Number(amount) * 0.36;
      mainROI = Number(amount) + _roi;
      setROI(mainROI);
      setUserROI(0);
    } else {
      mainROI = 0;
      setROI(mainROI);
      setUserROI(0);
    }
  };

  const calMonthlyDate = () => {
    if (ROIPackage.toString().includes("9%")) {
      setDueDate(getFullDateInMonth(3));
    } else if (ROIPackage.toString().includes("18%")) {
      setDueDate(getFullDateInMonth(6));
    } else if (ROIPackage.toString().includes("36%")) {
      setDueDate(getFullDateInMonth(12));
    } else {
      setDueDate(0);
    }
  };

  const calYearlyDate = (year) => {
    let currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setStartDate(`${currentDay}/${currentMonth}/${currentYear}`);
    if (year === 0) {
      setYearlyDueDate("");
    } else if (year === "1") {
      setYearlyDueDate(getFullDateInYears(1));
    } else if (year === "2") {
      setYearlyDueDate(getFullDateInYears(2));
    } else if (year === "3") {
      setYearlyDueDate(getFullDateInYears(3));
    } else if (year === "4") {
      setYearlyDueDate(getFullDateInYears(4));
    } else {
      setYearlyDueDate(getFullDateInYears(5));
    }
  };

  const OpenModal = () => {
    if (year === 0 || ROIPackage === "") {
      toast.error("Please fill out the form");
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    calROI(product?.transport?.amount);
    calYearlyDate(year);
    calMonthlyDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calROI, product?.transport?.amount, year]);

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="invest-main-con">
          <div className="dash-header-main-con">
            <p className="invest-header-text">Invest</p>
            <div className="bell-profile">
              <ProfileImage img={user?.user.user_metadata?.profile_url} />
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>
          <form className="invest-form-layout" onSubmit={handleSubmit}>
            <div className="invest-form">
              <input
                type="text"
                defaultValue={`${user?.user.user_metadata?.firstName} ${user?.user.user_metadata?.lastName}`}
                disabled
                className="form-control-input"
              />
              <input
                type="text"
                defaultValue={`${user?.user.user_metadata?.phoneNumber}`}
                disabled
                className="form-control-input"
              />
              <input
                type="text"
                defaultValue={`${
                  product?.transport?.name === undefined
                    ? ""
                    : product?.transport?.name
                }`}
                className="form-control-input"
              />
              <input
                type="text"
                defaultValue={`${
                  product?.transport?.amount === undefined
                    ? ""
                    : numeral(product?.transport?.amount).format("0,0")
                }`}
                className="form-control-input"
              />
              <div className="input-container invest-form-space">
                <DropdownInput
                  placeHolder="ROI Package"
                  onChange={onChange}
                  options={ROIOption}
                  className="invest-form-space"
                />
              </div>
              <div className="input-container invest-form-space">
                <DropdownInput
                  placeHolder="Duration"
                  onChange={yearsOnChange}
                  options={transportDurationOption}
                  className="invest-form-space"
                />
              </div>

              <div className="invest-form-btn">
                <Button
                  text="Submit"
                  className="colored-btn"
                  onClick={OpenModal}
                />
              </div>
            </div>
          </form>
          {isOpen && (
            <Modal
              setIsOpen={setIsOpen}
              modalId2="modal-block"
              modalId1="modal-none"
              modalId3="modal-none"
              investmentAmount={product?.transport?.amount}
              invest_data={transportObj}
              type="Transportation"
            />
          )}
        </section>
        <div className="dash-footer-con">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Investment;
