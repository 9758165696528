import React from "react";
import "./dash.css";

const DashCashCon2 = ({ className, cashText, amount }) => {
  return (
    <div id="dash-cash-con2" className={className}>
      <p id="dash-cash-header2">{cashText}</p>
      <p id="dash-cash-text2">{amount}</p>
    </div>
  );
};

export default DashCashCon2;