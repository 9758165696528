import React from 'react'
import { Spinner } from '../spinner/Spinner'
import "./style/button.css"

const Button = ({text, className,  onClick, icon, loading}) => {
  return (
    <>
    {loading ? <div className='but-spinner-con'><Spinner /></div> : <button className={`button ${className}`} onClick={onClick}><span className='button-icon-text'>{icon}</span>{text}</button>}
    </>
    
  )
}

export default Button