import { supabase } from "../services/supabase";
import React, { useContext, useState, useEffect } from "react";
import { calculation } from "../components/utilities/transportRoiCalculation";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [totalRealestateinvestment, setTotalRealestateinvestment] = useState(
    []
  );
  const [totalRealestateROI, setTotalRealestateROI] = useState([]);
  const [totalTransportInvetment, setTotalTransportationInvestment] = useState(
    []
  );
  const [totalTransportROI, setTotalTransportROI] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState([]);
  const [totalROI, setTotalROI] = useState([]);
  const [totalLoan, setTotalLoan] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);

  const [dates, setDates] = useState([]);
  const [datesInNumbers, setDatesInNumbers] = useState([]);

  useEffect(() => {
    const session = supabase.auth.session();
    setLoading(false);

    if (session !== null) {
      setAuthenticated(true);
      setUser(session);
      setLoading(false);
    }
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setAuthenticated(true);
        setLoading(false);
      }
      setLoading(false);
      setUser(session);
    });
  }, []);

  const getTotalRealestateInvestment = () => {
    supabase
      .from("investments")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        var investment = response.data;

        function total() {
          var total = 0;
          for (let i = 0; i < investment.length; i++) {
            const [day, month, year] =
              investment[i].meta_data.dueDate?.split("/");
            const [startDay, startMonth, startYear] =
              investment[i].meta_data.startDate?.split("/");
            setDates((current) => [
              ...current,
              {
                endingDate: new Date(year, month - 1, day),
                startDate: new Date(startYear, startMonth - 1, startDay),
              },
            ]);
            setDatesInNumbers((current) => [
              ...current,
              investment[i].meta_data.endDate
            ]);
            total += +investment[i].property.amount;
          }
          return total;
        }
        return setTotalRealestateinvestment(total());
      })
      .catch((error) => {});
  };

  const getTotalTransportInvetment = () => {
    supabase
      .from("transportation-investment")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        var investment = response.data;
        function total() {
          var total = 0;
          for (let i = 0; i < investment.length; i++) {
            const [day, month, year] =
              investment[i].meta_data.yearlyDueDate?.split("/");
            const [startDay, startMonth, startYear] =
              investment[i].meta_data.startDate?.split("/");
            setDates((current) => [
              ...current,
              {
                endingDate: new Date(year, month - 1, day),
                startDate: new Date(startYear, startMonth - 1, startDay),
              },
            ]);
            setDatesInNumbers((current) => [
              ...current,
              investment[i].meta_data.endDate
            ]);
            if (calculation(investment[i]) !== undefined) {
              let my_object = {};
              my_object.id = calculation(investment[i]).id;
              my_object.Roi = calculation(investment[i]).Roi;
              const newMeta = {
                ...investment[i].meta_data,
                userROI: my_object.Roi,
              };

              const newTransportData = {
                ...investment[i].transport,
                userROI: my_object.Roi,
              };
              const UpdateUserRoi = async () => {
                await supabase
                  .from("transportation-investment")
                  .update({
                    meta_data: newMeta,
                  })

                  .eq("id", my_object.id)
                  .select();
                await supabase
                  .from("transportation-investment")
                  .update({
                    transport: newTransportData,
                  })

                  .eq("id", my_object.id)
                  .select();
              };
              UpdateUserRoi();
            }
            total += +investment[i].transport.amount;
          }
          return total;
        }
        return setTotalTransportationInvestment(total());
      })
      .catch((error) => {});
  };

  const gettotalRealestateROI = () => {
    supabase
      .from("investments")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        var ROI = response.data;

        function total() {
          var total = 0;
          for (let i = 0; i < ROI.length; i++) {
            total += +ROI[i].property.userROI;
          }
          return total;
        }
        return setTotalRealestateROI(total());
      })
      .catch((error) => {});
  };

  const gettotalTransportROI = () => {
    supabase
      .from("transportation-investment")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        var ROI = response.data;
        function total() {
          var total = 0;
          for (let i = 0; i < ROI.length; i++) {
            total += +ROI[i].transport.userROI;
          }
          return total;
        }
        return setTotalTransportROI(total());
      })
      .catch((error) => {});
  };

  const getTotalLoan = () => {
    supabase
      .from("Loans")
      .select("*")
      .eq("user", user?.user.user_metadata.email)
      .then((response) => {
        var loan = response.data;

        function total() {
          var total = 0;
          for (let i = 0; i < loan.length; i++) {
            total += +loan[i].payment.amount;
          }
          return total;
        }

        return setTotalLoan(total());
      })
      .catch((error) => {});
  };

  const getTotalInvestment = () => {
    const totInvest = totalRealestateinvestment + totalTransportInvetment;
    setTotalInvestment(totInvest);
  };

  const getTotalROI = () => {
    const totROI = totalRealestateROI + totalTransportROI;
    setTotalROI(totROI);
  };

  const value = {
    dates,
    datesInNumbers,
    user,
    authenticated,
    totalRealestateinvestment,
    getTotalRealestateInvestment,
    totalRealestateROI,
    gettotalRealestateROI,
    totalLoan,
    getTotalLoan,
    getTotalTransportInvetment,
    totalTransportInvetment,
    getTotalInvestment,
    totalInvestment,
    totalTransportROI,
    getTotalROI,
    gettotalTransportROI,
    totalROI,
    setAuthenticated,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
