import React from 'react'
import "./style/input.css"
import { BsFillEyeSlashFill } from "react-icons/bs";
import { IoEyeSharp } from "react-icons/io5";

const Input = ({ icon, type, placeholder, className, conClass, name, value, onChange, passwordError, passwordType, togglePassword, id }) => {
  
  return (
    <>
      <div className={`input-con ${conClass}`}>
        <div className='input-img-con'>
          <img src={icon} alt="#" />
        </div>
        <input type={type} placeholder={placeholder} className={`input ${className}`} name={name}
          value={value}
          onChange={onChange} required />
          <div className="input-group-btn" id={id} >
            <div className="eye-div" onClick={togglePassword}>
              {passwordType === "password" ? <BsFillEyeSlashFill className='eye'/> : <IoEyeSharp className='eye'/>}
            </div>
          </div>
      </div>
      <p className="text-danger">{passwordError}</p>
    </>
  )
}

export default Input