import React, { useEffect } from 'react'
import { FaBell } from "react-icons/fa";
import Footer from '../../Footer/Footer';
import "./style/transport.css"
import AllItems from '../../AllItems/Transport allitems/Allitems';
import { useProductsContext } from '../../Context/product_context';
import { useAuth } from '../../../providers/auth.provider';
import ProfileImage from '../../profile-img/Layout';

const Transport = () => {
    const { user } = useAuth();
    const { fetchTransport, transports } = useProductsContext();

    useEffect(() => {
        fetchTransport();
        
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <div id="dash-main-con">
            <div className='get-loan-right'>
                <section className='get-loan-right-main'>
                    <div className="dash-header-main-con">
                        <p className="get-loan-header-text">Transport</p>
                        <div className="bell-profile">
                        <ProfileImage img={user?.user.user_metadata?.profile_url} />
                            <div>
                                <FaBell className="bell-con" />
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: "30px"}}>
                    {transports !== undefined? <AllItems items={transports} /> : <div></div>}
                    </div>
                </section>
                <div className="dash-footer-con">

                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Transport