import React from "react";
import { Link } from "react-router-dom"

import { useAuth } from "../../providers/auth.provider";

import "./style/not-found.css"
const PageNotFound = () => {
    const { authenticated } = useAuth();

    return (
        <div className="page-not-found">
            <div >
                <div className="not-found-con container-star">
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-1"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                    <div className="star-2"></div>
                </div>
                <div className="not-found-con container-bird">
                    <div className="birds-main-con">
                        <div className="bird bird-anim">
                            <div className="bird-container">
                                <div className="wing wing-left">
                                    <div className="wing-left-top"></div>
                                </div>
                                <div className="wing wing-right">
                                    <div className="wing-right-top"></div>
                                </div>
                            </div>
                        </div>
                        <div className="bird bird-anim">
                            <div className="bird-container">
                                <div className="wing wing-left">
                                    <div className="wing-left-top"></div>
                                </div>
                                <div className="wing wing-right">
                                    <div className="wing-right-top"></div>
                                </div>
                            </div>
                        </div>
                        <div className="bird bird-anim">
                            <div className="bird-container">
                                <div className="wing wing-left">
                                    <div className="wing-left-top"></div>
                                </div>
                                <div className="wing wing-right">
                                    <div className="wing-right-top"></div>
                                </div>
                            </div>
                        </div>
                        <div className="bird bird-anim">
                            <div className="bird-container">
                                <div className="wing wing-left">
                                    <div className="wing-left-top"></div>
                                </div>
                                <div className="wing wing-right">
                                    <div className="wing-right-top"></div>
                                </div>
                            </div>
                        </div>
                        <div className="bird bird-anim">
                            <div className="bird-container">
                                <div className="wing wing-left">
                                    <div className="wing-left-top"></div>
                                </div>
                                <div className="wing wing-right">
                                    <div className="wing-right-top"></div>
                                </div>
                            </div>
                        </div>
                        <div className="bird bird-anim">
                            <div className="bird-container">
                                <div className="wing wing-left">
                                    <div className="wing-left-top"></div>
                                </div>
                                <div className="wing wing-right">
                                    <div className="wing-right-top"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-title">
                        <div className="title">
                            <div className="number">4</div>
                            <div className="moon">
                                <div className="face">
                                    <div className="mouth"></div>
                                    <div className="eyes">
                                        <div className="eye-left"></div>
                                        <div className="eye-right"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="number">4</div>
                        </div>
                        <div className="subtitle">Oops. Looks like you took a wrong turn.</div>
                        {authenticated ? <Link to='/dashboard'><button className="not-found-btn">Go to your dashboard</button></Link> : <Link to='/'><button className="not-found-btn">Go back</button></Link>}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PageNotFound;