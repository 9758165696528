import React from 'react'
import AddInvestments from '../../components/Admin Dashboard/Add invest/AddInvestments';

const AddInvestmentPage = () => {
    window.scrollTo(0, 0);
  return (
    <AddInvestments />
  )
}

export default AddInvestmentPage