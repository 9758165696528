import React, { useEffect } from 'react'
import { FaBell } from 'react-icons/fa'
import { useNavigate } from "react-router-dom"
import { Table } from 'antd';

import { useAdmin } from '../../../providers/admin.provider';
import Footer from '../../Footer/Footer';
import "./styles/allusers.css"
import { Spinner } from '../../spinner/Spinner';

const AllUsers = () => {
  const history = useNavigate();
  const { getAllUsers, allUsers, loading } = useAdmin()

  useEffect(() => {
    getAllUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'S/N',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, record) => {
        return (<p className='users-full-name'>{record.meta_data.firstName} {record.meta_data.lastName}</p>)
      },
    },
    {
      title: 'Email Adress',
      dataIndex: ['meta_data', 'email'],
      key: ['meta_data', 'email'],
    },
    {
      title: 'Phone Number',
      dataIndex: ['meta_data', 'phoneNumber'],
      key: ['meta_data', 'phoneNumber'],
    },
  ];

  return (
    <div id="dash-main-con">
      <div className='get-loan-right'>
        <section className='get-loan-right-main'>
          <div className="dash-header-main-con">
            <h2 className="real-estate-header-text"> All Users</h2>
            <div className="bell-profile">
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>

            <Table columns={columns} loading={{
              indicator:
                <Spinner />, spinning: loading
            }} dataSource={allUsers}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {history(`/admin-users/${record.id}`)}
              }}}
              rowKey="id" />
        </section>
        <div className="dash-footer-con">

          <Footer />
        </div>
      </div>
    </div>
  )
}

export default AllUsers