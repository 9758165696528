import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer" >
      <div className="footer__copyright__text">
        <p className="footer-copy-right footer-header">Copyright @ 2022 MO GROUP LTD All Right Reserved </p>
      </div>
    </footer>
  );
};

export default Footer;
