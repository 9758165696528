import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import DashCashCon from "../../DashBoard/DashCashCon";
import DashConCash2 from "../../DashBoard/DashConCash2";
import { supabase } from "../../../services/supabase";
import DashAccountStatement from '../../DashBoard/account statment/DashAccountStatement';
import DashLoanStatement from '../../DashBoard/account statment/DashLoanStatement';
import Footer from '../../Footer/Footer';
import { useAdmin } from '../../../providers/admin.provider';
import { DropdownInput } from "../../input/dropdown-input-v2/DropdownInput";
import { investmentOption } from "../../options"
import numeral from 'numeral'

const Home = () => {
    const { id } = useParams();
    const { allUsers } = useAdmin()

    const product = allUsers?.find((product) => String(product.id) === id);
    const [activeSection, setActiveSection] = useState(0);
    const [allLoans, setallLoans] = useState([])
    const [totalLoan, setTotalLoan] = useState(0)
    const [totalInvestment, setTotalInvestment] = useState(0)
    const [totalROI, setTotalROI] = useState(0)
    const [totalRealestateinvestment, setTotalRealestateinvestment] = useState(0)
    const [totalRealestateROI, setTotalRealestateROI] = useState(0)
    const [totalTransportInvetment, setTotalTransportationInvestment] = useState(0)
    const [totalTransportROI, setTotalTransportROI] = useState(0)
    const [loading, setloading] = useState(true)
    const [allinvestments, setallinvestments] = useState([])
    const [type, setType] = useState('Real Estate')
    const [investOptions, setInvestOptions] = useState('Real Estate')

    const sections = [<DashConCash2 cashText="Total Income" amount={`NGN ${numeral(totalInvestment).format('0,0')}`} className="dash-wid-two" />
        , <DashConCash2 cashText="Total Loan" amount={`NGN ${numeral(totalLoan).format('0,0')}`} className="dash-wid-two" />
        , <DashConCash2 cashText="Total Savings" amount="NGN 0" className="dash-wid-two" />];

    const changeActiveSection = (index) => {
        setActiveSection(index);
    };

    const handleDropdownChange = (value) => {
        setInvestOptions(value)
    };

    const getLoans = () => {
        setloading(true)
        supabase
            .from("Loans")
            .select("*")
            .eq("user", product?.meta_data?.email)
            .then(response => {
                setallLoans(response.data)
                setloading(false)
                var loan = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < loan.length; i++) {
                        total += +(loan[i].payment.amount);
                    }
                    return total;
                }

                return setTotalLoan(total())

            })
            .catch(error => {
                setloading(false)
            })
    }

    const getInvestments = () => {
        setloading(true)
        if (investOptions === "Real Estate") {
            setType('Real Estate')
            supabase
                .from("investments")
                .select("*")
                .eq("user", product?.meta_data?.email)
                .then(response => {

                    setallinvestments(response.data)
                    setloading(false)
                })
                .catch(error => {
                    setloading(false)
                })
        }
        else {
            setType('Transport')
            supabase
                .from("transportation-investment")
                .select("*")
                .eq("user", product?.meta_data?.email)
                .then(response => {

                    setallinvestments(response.data)
                    setloading(false)
                })
                .catch(error => {
                    setloading(false)
                })
        }

    }
    const getTotalRealestateInvestment = () => {
        supabase
            .from("investments")
            .select("*")
            .eq("user", product?.meta_data?.email)
            .then(response => {
                var investment = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < investment.length; i++) {
                        total += +(investment[i].property.amount);
                    }
                    return total;
                }
                return setTotalRealestateinvestment(total())
            })
            .catch(error => {
            })

    }

    const getTotalTransportInvetment = () => {
        supabase
            .from("transportation-investment")
            .select("*")
            .eq("user", product?.meta_data?.email)
            .then(response => {
                var investment = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < investment.length; i++) {
                        total += +(investment[i].transport.amount);
                    }
                    return total;
                }
                return setTotalTransportationInvestment(total())
            })
            .catch(error => {
            })

    }

    const gettotalRealestateROI = () => {
        supabase
            .from("investments")
            .select("*")
            .eq("user", product?.meta_data?.email)
            .then(response => {
                var ROI = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < ROI.length; i++) {
                        total += +(ROI[i].property.userROI);
                    }
                    return total;
                }
                return setTotalRealestateROI(total())
            })
            .catch(error => {
            })

    }

    const gettotalTransportROI = () => {
        supabase
            .from("transportation-investment")
            .select("*")
            .eq("user", product?.meta_data?.email)
            .then(response => {
                var ROI = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < ROI.length; i++) {
                        total += +(ROI[i].transport.userROI);
                    }
                    return total;
                }
                return setTotalTransportROI(total())
            })
            .catch(error => {
            })

    }

    const getTotalLoan = () => {
        supabase
            .from("Loans")
            .select("*")
            .eq("user", product?.meta_data?.email)
            .then(response => {
                var loan = response.data;

                function total() {
                    var total = 0;
                    for (let i = 0; i < loan.length; i++) {
                        total += +(loan[i].payment.amount);
                    }
                    return total;
                }

                return setTotalLoan(total())
            })
            .catch(error => {
            })

    }

    const getTotalInvestment = () => {
        const totInvest = totalRealestateinvestment + totalTransportInvetment
        setTotalInvestment(totInvest)
    }

    const getTotalROI = () => {
        const totROI = totalRealestateROI + totalTransportROI
        setTotalROI(totROI)
    }

    useEffect(() => {
        getInvestments();
        getLoans();
        getTotalRealestateInvestment();
        gettotalRealestateROI();
        getTotalLoan();
        getTotalTransportInvetment();
        getTotalInvestment();
        getTotalROI();
        gettotalTransportROI();
        // eslint-disable-next-line
    }, [allUsers,totalRealestateinvestment, totalTransportInvetment, totalTransportROI, totalRealestateROI, investOptions])



    return (
        <div id="dash-main-con" className="dashboard">
            <section id="dash-right-con">
                <div className="dash-header-main-con">
                    <h2 className="dashboard-right-text">{product?.meta_data?.firstName} Account,</h2>
                    <Link to="/admin-users" className="dash-account-statement-all-link">Back</Link>

                </div >
                <div id="dash-layout" >
                    <DashCashCon cashText="Total Investment" amount={`NGN ${numeral(totalInvestment).format('0,0')}`} className="dash-wid-one" />
                    <DashCashCon cashText="Total ROI" amount={`NGN ${numeral(totalROI).format('0,0')}`} className="dash-wid-one" />
                    <DashCashCon cashText="Account Balance" amount={`NGN ${numeral(product?.meta_data?.balance).format('0,0')}`} className="dash-wid-one" />
                </div >



                <div className="dashboard-select-layout" >
                    {sections.map((section, index) => {
                        return (
                            <div
                                key={index}
                                index={index}
                                onClick={() => changeActiveSection(index)}
                                className={`dash-active-btn ${activeSection === index ? "dash-active-btn-select" : ""
                                    }`}>
                                {section}
                            </div>
                        )
                    })}
                </div>
                <div className="dash-select-con admin-invest-options" >
                    <DropdownInput
                        defaultValue="Real Estate"
                        onChange={handleDropdownChange}
                        options={investmentOption}
                    />
                </div >
                {activeSection === 0 ? <DashAccountStatement text="Total Income" loading={loading} type={type} options={allinvestments} email={product?.meta_data?.email}/> : activeSection === 1 ? <DashLoanStatement text="Total Loans" options={allLoans} loading={loading} email={product?.meta_data?.email}/> : <DashAccountStatement loading={false} text="Total Savings" className="view-display" />}
                <div id="display-con" className="image-grid grid-space" >
                </div >
                < Footer />
            </section >
        </div>
    )
}

export default Home