import React from "react";
import "./styles/dash-acc.css";
import numeral from "numeral";

import cash from "../../../Asset/Icons/cash2.svg";
import { Spinner } from "../../spinner/Spinner";
import { Link } from "react-router-dom";

const DashAccountStatement = (props) => {
  const { options, text, loading, type, admin, email, className } = props;

  if (admin === true) {
    if (type === "Real Estate") {
      return (
        <>
          <div id="dash-account-statement-con">
            <div className="dash-account-statement-all">
              <p className="dash-account-tran">{`Transactions | ${text}`}</p>
              <Link
                to="/admin/investments"
                className={`dash-account-statement-all-link ${className}`}
              >
                view all
              </Link>
            </div>
            {loading === false ? (
              <div id="dash-account-statement-slide-con">
                <div className="dash-account-user-container">
                  {options?.slice(0, 6).map((opt) => {
                    return (
                      <div className="dash-account-statement" key={opt.id}>
                        <div className="dash-account-img-con">
                          <img src={cash} alt="#" />
                          <p className="dash-account-des">
                            {opt.property?.name}
                          </p>
                        </div>
                        <p className="dash-account-amount">
                          NGN {numeral(opt.property?.amount).format("0,0")}
                        </p>
                        <p className="dash-account-date">
                          Due Date: {opt.property?.dueDate}
                        </p>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            ) : (
              <div className="loading-spinner-con">
                <Spinner />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div id="dash-account-statement-con">
            <div className="dash-account-statement-all">
              <p className="dash-account-tran">{`Transactions | ${text}`}</p>
              <Link
                to="/admin/investments"
                className={`dash-account-statement-all-link ${className}`}
              >
                view all
              </Link>
            </div>
            {loading === false ? (
              <div id="dash-account-statement-slide-con">
                <div
                  className="dash-account-user-container"
                  id="dash-account-transport"
                >
                  {options?.slice(0, 6).map((opt) => {
                    return (
                      <div className="dash-account-statement" key={opt.id}>
                        <div className="dash-account-img-con">
                          <img src={cash} alt="#" />
                          <p className="dash-account-des">
                            {opt.transport?.name}
                          </p>
                        </div>
                        <p className="dash-account-amount">
                          NGN {numeral(opt.transport?.amount).format("0,0")}
                        </p>
                        <p className="dash-account-date">
                          Due Date: {opt.transport?.yearlyDueDate}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="loading-spinner-con">
                <Spinner />
              </div>
            )}
          </div>
        </>
      );
    }
  } else if (email) {
    if (type === "Real Estate") {
      return (
        <>
          <div id="dash-account-statement-con">
            <div className="dash-account-statement-all">
              <p className="dash-account-tran">{`Transactions | ${text}`}</p>
              <Link
                to={`/admin/investments/${email}`}
                className={`dash-account-statement-all-link ${className}`}
              >
                view all
              </Link>
            </div>
            {loading === false ? (
              <div id="dash-account-statement-slide-con">
                <div className="dash-account-user-container">
                  {options?.slice(0, 6).map((opt) => {
                    return (
                      <div className="dash-account-statement" key={opt.id}>
                        <div className="dash-account-img-con">
                          <img src={cash} alt="#" />
                          <p className="dash-account-des">
                            {opt.property?.name}
                          </p>
                        </div>
                        <p className="dash-account-amount">
                          NGN {numeral(opt.property?.amount).format("0,0")}
                        </p>
                        <p className="dash-account-date">
                          Due Date: {opt.property?.dueDate}
                        </p>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            ) : (
              <div className="loading-spinner-con">
                <Spinner />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div id="dash-account-statement-con">
            <div className="dash-account-statement-all">
              <p className="dash-account-tran">{`Transactions | ${text}`}</p>
              <Link
                to="/dashboard/investments"
                className={`dash-account-statement-all-link ${className}`}
              >
                view all
              </Link>
            </div>
            {loading === false ? (
              <div id="dash-account-statement-slide-con">
                <div
                  className="dash-account-user-container"
                  id="dash-account-transport"
                >
                  {options?.slice(0, 6).map((opt) => {
                    return (
                      <div className="dash-account-statement" key={opt.id}>
                        <div className="dash-account-img-con">
                          <img src={cash} alt="#" />
                          <p className="dash-account-des">
                            {opt.transport?.name}
                          </p>
                        </div>
                        <p className="dash-account-amount">
                          NGN {numeral(opt.transport?.amount).format("0,0")}
                        </p>
                        <p className="dash-account-date">
                          Due Date: {opt.transport?.yearlyDueDate}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="loading-spinner-con">
                <Spinner />
              </div>
            )}
          </div>
        </>
      );
    }
  } else {
    if (type === "Real Estate") {
      return (
        <>
          <div id="dash-account-statement-con">
            <div className="dash-account-statement-all">
              <p className="dash-account-tran">{`Transactions | ${text}`}</p>
              <Link
                to="/dashboard/investments"
                className={`dash-account-statement-all-link ${className}`}
              >
                view all
              </Link>
            </div>
            {loading === false ? (
              <div id="dash-account-statement-slide-con">
                <div className="dash-account-user-container">
                  {options?.slice(0, 6).map((opt) => {
                    return (
                      <div className="dash-account-statement" key={opt.id}>
                        <div className="dash-account-img-con">
                          <img src={cash} alt="#" />
                          <p className="dash-account-des">
                            {opt.property?.name}
                          </p>
                        </div>
                        <p className="dash-account-amount">
                          NGN {numeral(opt.property?.amount).format("0,0")}
                        </p>
                        <p className="dash-account-date">
                          Due Date: {opt.property?.dueDate}
                        </p>
                      </div>
                    );
                  })}{" "}
                </div>
              </div>
            ) : (
              <div className="loading-spinner-con">
                <Spinner />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div id="dash-account-statement-con">
            <div className="dash-account-statement-all">
              <p className="dash-account-tran">{`Transactions | ${text}`}</p>
              <Link
                to="/dashboard/investments"
                className={`dash-account-statement-all-link ${className}`}
              >
                view all
              </Link>
            </div>
            {loading === false ? (
              <div id="dash-account-statement-slide-con">
                <div
                  className="dash-account-user-container"
                  id="dash-account-transport"
                >
                  {options?.slice(0, 6)?.map((opt) => {
                    return (
                      <div className="dash-account-statement" key={opt.id}>
                        <div className="dash-account-img-con">
                          <img src={cash} alt="#" />
                          <p className="dash-account-des">
                            {opt.transport?.name}
                          </p>
                        </div>
                        <p className="dash-account-amount">
                          NGN {numeral(opt.transport?.amount).format("0,0")}
                        </p>
                        <p className="dash-account-date">
                          Due Date: {opt.transport?.yearlyDueDate}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="loading-spinner-con">
                <Spinner />
              </div>
            )}
          </div>
        </>
      );
    }
  }
};

export default DashAccountStatement;
