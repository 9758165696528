import React from 'react'
import "./styles/bottom-nav.css"
import {
    FaTh,
    FaBusAlt
} from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BsFillHouseFill, BsPeopleFill, BsFillBagFill, BsCashCoin } from "react-icons/bs"
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../providers/auth.provider';
import { toast } from 'react-toastify';
import { FiSettings } from "react-icons/fi";
import { signOut } from "../../../../services/auth.service";

const BottomNav = ({admin}) => {
    const { setAuthenticated } = useAuth();

    const history = useNavigate();
    const menuItem = [
        {
            path: "/dashboard",
            icon: <FaTh />
        },
        {
            path: "/RealEstate",
            icon: <BsFillHouseFill />
        },
        {
            path: "/transport",
            icon: <FaBusAlt />
        },
        {
            path: "/Getloan",
            icon: <BsCashCoin />
        },
        {
            path: "/invest",
            icon: <BsFillBagFill />
        },
        {
            path: "/profile",
            icon: <CgProfile />
        }
    ]

    const adminMenuItem = [
        {
            path: "/admin",
            name: "Home",
            icon: <FaTh />
        },
        {
            path: "/admin-realEstate",
            name: "Real Estate",
            icon: <BsFillHouseFill />
        },
        {
            path: "/admin-transport",
            name: "Transport",
            icon: <FaBusAlt />
        },
        {
            path: "/admin-add",
            name: "Add Investments",
            icon: <BsFillBagFill />
        },
        {
            path: "/admin-users",
            name: "All users",
            icon: <BsPeopleFill />
        },
        {
            path: "/profile",
            name: "Settings",
            icon: <FiSettings />
        }
    ]

    const handleSignOut = async () => {
        try {
            let { error } = await signOut();
            if (error === null) {
                setAuthenticated(false)
                history('/login');
            }


        } catch (error) {
            console.log(error);
            toast.error(error)
        }
    }

    if (admin !== undefined) {
        return (
            <div className='bottom-nav'>
                {
                    adminMenuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeclassname="active">
                            <div className="icon">{item.icon}</div>
                        </NavLink>
                    ))
                }
                <div className="link" activeclassname="active" onClick={handleSignOut}>
                    <div className="icon"><RiLogoutCircleLine /></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='bottom-nav'>
                {
                    menuItem.map((item, index) => (
                        <NavLink to={item.path} key={index} className="link" activeclassname="active">
                            <div className="icon">{item.icon}</div>
                        </NavLink>
                    ))
                }
                <div className="link" activeclassname="active" onClick={handleSignOut}>
                    <div className="icon"><RiLogoutCircleLine /></div>
                </div>
            </div>
        )
    }
}

export default BottomNav