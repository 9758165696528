import React, { useState, useEffect } from "react";
import DashCashCon from "../../DashBoard/DashCashCon";
import DashConCash2 from "../../DashBoard/DashConCash2";
import { supabase } from "../../../services/supabase";
import { FaBell } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import DashAccountStatement from "../../DashBoard/account statment/DashAccountStatement";
import DashLoanStatement from "../../DashBoard/account statment/DashLoanStatement";
import Modal from "../../modal/Modal";
import Footer from "../../Footer/Footer";
import Layout from "../../image layout/Layout";
import { useAuth } from "../../../providers/auth.provider";
import { useAdmin } from "../../../providers/admin.provider";
import { DropdownInput } from "../../input/dropdown-input-v2/DropdownInput";
import { investmentOption } from "../../options";
import numeral from "numeral";
import "./style/admin-home.css";
import { useProductsContext } from "../../Context/product_context";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  LargeDesktop: {
    breakpoint: { max: 3000, min: 1800 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1800, min: 1200 },
    items: 3,
  },
  smallLaptop: {
    breakpoint: { max: 1200, min: 992 },
    items: 2.1,
  },
  tablet: {
    breakpoint: { max: 992, min: 700 },
    items: 2,
  },
  smallTablet: {
    breakpoint: { max: 700, min: 500 },
    items: 1.5,
  },
  mobile: {
    breakpoint: { max: 500, min: 0 },
    items: 1,
  },
};

const Home = () => {
  const { user } = useAuth();
  const {
    totalRealestateinvestment,
    getTotalRealestateInvestment,
    totalRealestateROI,
    totalTransportROI,
    gettotalRealestateROI,
    getTotalLoan,
    totalLoan,
    getTotalTransportInvetment,
    totalTransportInvetment,
    getTotalInvestment,
    totalInvestment,
    getTotalROI,
    totalROI,
    gettotalTransportROI,
    getTotalAccountBalance,
    totalAccountBalance,
  } = useAdmin();
  const { products } = useProductsContext();

  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [allLoans, setallLoans] = useState([]);
  const [loading, setloading] = useState(true);
  const [allinvestments, setallinvestments] = useState([]);
  const [type, setType] = useState("Real Estate");
  const [investOptions, setInvestOptions] = useState("Real Estate");

  const sections = [
    {
      element: (
        <DashConCash2
          cashText={`Total Investment ${
            type === "Real Estate" ? "(Real Estate)" : "(Transport)"
          }`}
          amount={`NGN ${
            type === "Real Estate"
              ? numeral(totalRealestateinvestment).format("0,0")
              : numeral(totalTransportInvetment).format("0,0")
          }`}
          className="dash-wid-two"
        />
      ),
      className: "total-income",
    },
    {
      element: (
        <DashConCash2
          cashText="Total Loan"
          amount={`NGN ${numeral(totalLoan).format("0,0")}`}
          className="dash-wid-two"
        />
      ),
      className: "",
    },
    {
      element: (
        <DashConCash2
          cashText="Total Savings"
          amount="NGN 0"
          className="dash-wid-two"
        />
      ),
      className: "",
    },
  ];

  const changeActiveSection = (index) => {
    setActiveSection(index);
  };

  const handleDropdownChange = (value) => {
    setInvestOptions(value);
  };

  const getLoans = () => {
    setloading(true);
    supabase
      .from("Loans")
      .select("*")
      .then((response) => {
        setallLoans(response.data);
        setloading(false);
      })
      .catch((error) => {
        setloading(false);
      });
  };
  const getInvestments = () => {
    setloading(true);
    if (investOptions === "Real Estate") {
      setType("Real Estate");
      supabase
        .from("investments")
        .select("*")
        .then((response) => {
          setallinvestments(response.data);
          setloading(false);
        })
        .catch((error) => {
          // toast.error("Network Error")
          setloading(false);
        });
    } else {
      setType("Transport");
      supabase
        .from("transportation-investment")
        .select("*")
        .then((response) => {
          setallinvestments(response.data);
          setloading(false);
        })
        .catch((error) => {
          // toast.error("Network Error")
          setloading(false);
        });
    }
  };

  useEffect(() => {
    getInvestments();
    getLoans();
    getTotalRealestateInvestment();
    gettotalRealestateROI();
    getTotalLoan();
    getTotalTransportInvetment();
    getTotalInvestment();
    getTotalROI();
    gettotalTransportROI();
    getTotalAccountBalance();
    // eslint-disable-next-line
  }, [
    totalRealestateinvestment,
    totalTransportInvetment,
    totalTransportROI,
    totalRealestateROI,
    totalAccountBalance,
    investOptions,
  ]);

  return (
    <div id="dash-main-con" className="dashboard">
      <section id="dash-right-con">
        <div className="dash-header-main-con">
          <h2 className="dashboard-right-text">
            Welcome {user?.user.user_metadata?.firstName},
          </h2>
          <div className="bell-profile">
            <div>
              <FaBell className="bell-con" />
            </div>
          </div>
        </div>
        <div id="dash-layout">
          <DashCashCon
            cashText="Total Investment"
            amount={`NGN ${numeral(totalInvestment).format("0,0")}`}
            className="dash-wid-one"
          />
          <DashCashCon
            cashText="Total ROI"
            amount={`NGN ${numeral(totalROI).format("0,0")}`}
            className="dash-wid-one"
          />
          <DashCashCon
            cashText="Account Balance"
            amount={`NGN ${numeral(totalAccountBalance).format("0,0")}`}
            className="dash-wid-one"
          />
        </div>

        <div className="dashboard-select-layout">
          {sections.map((section, index) => {
            return (
              <div
                key={index}
                index={index}
                onClick={() => changeActiveSection(index)}
                className={`dash-active-btn ${section.className} ${
                  activeSection === index ? "dash-active-btn-select" : ""
                }`}
              >
                {section.element}
              </div>
            );
          })}
        </div>
        <div className="dash-select-con admin-invest-options">
          <DropdownInput
            defaultValue="Real Estate"
            onChange={handleDropdownChange}
            options={investmentOption}
          />
        </div>
        {activeSection === 0 ? (
          <DashAccountStatement
            text="Total Income"
            loading={loading}
            type={type}
            options={allinvestments}
            admin={true}
          />
        ) : activeSection === 1 ? (
          <DashLoanStatement
            text="Total Loans"
            options={allLoans}
            loading={loading}
            admin={true}
          />
        ) : (
          <DashAccountStatement
            loading={false}
            text="Total Savings"
            className="view-display"
          />
        )}
        <div className="dash-carousel">
          <Carousel responsive={responsive}>
            {products.slice(0, 6).map((product) => {
              return (
                <Layout
                  key={product.id}
                  {...product}
                  id1="no-display"
                  id2="no-display"
                />
              );
            })}
          </Carousel>
        </div>
        <Footer />
      </section>
      {isOpen && (
        <Modal
          setIsOpen={setIsOpen}
          modalId1="modal-block"
          modalId2="modal-none"
        />
      )}
    </div>
  );
};

export default Home;
