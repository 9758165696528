import numeral from "numeral";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { supabase } from "../../services/supabase";
import Button from "../button/Button";
import "./styles/image-layout.css";

import { useProductsContext } from "../Context/product_context";

const Layout = ({ id, property, id1, id2, id3, onClick }) => {
  const { fetchProducts } = useProductsContext();

  const deleteInvestment = () => {
    supabase
      .from("Admin RealEstate")
      .delete()
      .match({ id: id })
      .then((response) => {
        toast.success('Deleted Successfully');
        fetchProducts();
      })
      .catch((error) => {});
  };

  return (
    <div className="image-des-con">
      <div className="img-con">
        {" "}
        <img src={property?.imageKey} alt="#" className="img" />
      </div>
      <div className="house-des">
        <p className="house-title">{property?.name}</p>
        <address className="house-address">{property?.address}</address>
        <p className="house-price">
          {" "}
          NGN {`${numeral(property?.amount).format("0,0")}`}
        </p>
        <div id={id3}>
          <Button
            text="Delete"
            className="colored-btn"
            onClick={deleteInvestment}
          />
        </div>
        <div id={id1}>
          <Link to="/login">
            <Button
              text="Invest Now"
              className="colored-btn"
              onClick={onClick}
            />
          </Link>
        </div>
        <Link to={`/invest/realEstate/${id}`} id={id2}>
          <Button text="Invest Now" className="colored-btn" />
        </Link>
      </div>
    </div>
  );
};

export default Layout;
