import React from 'react'
import GetLoan from "../components/DashBoard/get loan/GetLoan";

const GetLoanPage = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <GetLoan />
    </>
  )
}

export default GetLoanPage