import React, { useEffect, useState } from "react";
import { Table } from "antd";

import { useAuth } from "../../../../providers/auth.provider";
import { supabase } from "../../../../services/supabase";
import numeral from "numeral";
import { Spinner } from "../../../spinner/Spinner";
import { Link, useParams } from "react-router-dom";
import { getDueDate } from "../../../utilities/helper";
import Button from "../../../button/Button";
import Modal from "../../../modal/Modal";

const AllLoan = ({ admin, userEmail }) => {
  const { email } = useParams();
  const [allLoans, setallLoans] = useState([]);
  const [loanPaymentData, setLoanPaymentData] = useState([]);
  const [loanAmount, setLoanAmount] = useState([]);
  const [loading, setloading] = useState(true);
  const [columns, setcolumns] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    const getLoans = () => {
      if (admin === true) {
        setloading(true);
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data"],
            key: ["meta_data"],
            render: (text) => "Outstanding loan",
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "dueDate"],
            key: ["meta_data", "dueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("Loans")
          .select("*")
          .then((response) => {
            setallLoans(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      } else if (userEmail) {
        setloading(true);
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data"],
            key: ["meta_data"],
            render: (text) => "Outstanding loan",
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${numeral(text).format("0,0")}`,
          },
          {
            title: "Due Date",
            dataIndex: ["meta_data", "dueDate"],
            key: ["meta_data", "dueDate"],
          },
        ];
        setcolumns(columns);
        supabase
          .from("Loans")
          .select("*")
          .eq("user", email)
          .then((response) => {
            setallLoans(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      } else {
        setloading(true);
        const columns = [
          {
            title: "Name",
            dataIndex: ["meta_data"],
            key: ["meta_data"],
            render: (text) => "Outstanding loan",
          },
          {
            title: "Amount",
            dataIndex: ["meta_data", "amount"],
            key: ["meta_data", "amount"],
            render: (text) => `NGN ${` ${numeral(text).format("0,0")}`}`,
          },
          {
            title: "Due Date",
            dataIndex: [],
            key: [],
            render: (data) => {
              const loanPaymentObj = {
                amount: data.meta_data.amount,
                name:
                  user?.user.user_metadata?.firstName +
                  " " +
                  user?.user.user_metadata?.lastName,
                email: user?.user.user_metadata?.email,
                user,
                loanId: data.id,
              };
              setLoanAmount(data.meta_data.amount);
              setLoanPaymentData(loanPaymentObj);
              return (
                <div>
                  {getDueDate(data.meta_data.dueDate) === true ? (
                    <>
                      <Button
                        text="Pay Now"
                        className="colored-btn "
                        onClick={() => setIsOpen(true)}
                      />
                    </>
                  ) : (
                    <>{data.meta_data.dueDate}</>
                  )}
                </div>
              );
            },
          },
        ];
        setcolumns(columns);
        supabase
          .from("Loans")
          .select("*")
          .eq("user", user?.user.user_metadata.email)
          .then((response) => {
            setallLoans(response.data);
            setloading(false);
          })
          .catch((error) => {
            setloading(false);
          });
      }
    };
    getLoans();
  }, [admin, email, setallLoans, user, userEmail]);

  return (
    <div id="dash-main-con">
      <div className="get-loan-right">
        <section className="get-loan-right-main">
          <div className="dash-header-main-con">
            <p className="dash-account-tran">Loan</p>
            {admin === true ? (
              <Link to="/admin" className="dash-account-statement-all-link">
                Back to home
              </Link>
            ) : userEmail === true ? (
              <Link
                to="/admin-users"
                className="dash-account-statement-all-link"
              >
                All user
              </Link>
            ) : (
              <Link to="/dashboard" className="dash-account-statement-all-link">
                Back to DashBoard
              </Link>
            )}
          </div>

          <div className="dash-antd-table">
            <Table
              columns={columns}
              loading={{
                indicator: <Spinner />,
                spinning: loading,
              }}
              dataSource={allLoans}
              rowKey="id"
            />
          </div>
        </section>
      </div>
      {isOpen && (
        <Modal
          setIsOpen={setIsOpen}
          modalId3="modal-block"
          modalId1="modal-none"
          modalId2="modal-none"
          investmentAmount={loanAmount}
          invest_data={loanPaymentData}
        />
      )}
    </div>
  );
};

export default AllLoan;
