import React from 'react'
import AllUsers from '../../components/Admin Dashboard/All Users/AllUsers';

const AllUserPage = () => {
    window.scrollTo(0, 0);
  return (
    <AllUsers />
  )
}

export default AllUserPage