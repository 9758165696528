import React from 'react'
import Dashboard from "../components/DashBoard/DashBoard";

const DashboardPage = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Dashboard />
    </>
  )
}

export default DashboardPage