import React, { useState } from "react";
import { FaTh, FaBars, FaBusAlt } from "react-icons/fa";
import { RiLogoutCircleLine } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import {
  BsFillHouseFill,
  BsFillBagFill,
  BsCashCoin,
  BsPeopleFill,
} from "react-icons/bs";
import { NavLink, useNavigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";

import "./styles/dashboard-nav.css";
import { signOut } from "../../../services/auth.service";
import { useAuth } from "../../../providers/auth.provider";
import BottomNav from "./bottom-nav/BottomNav";
import logo from "../../../Asset/Icons/logo.png";

const DashboardNav = ({ children }) => {
  const { user, setAuthenticated } = useAuth();

  const history = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaTh />,
    },
    {
      path: "/RealEstate",
      name: "Real Estate",
      icon: <BsFillHouseFill />,
    },
    {
      path: "/transport",
      name: "Transport",
      icon: <FaBusAlt />,
    },
    {
      path: "/Getloan",
      name: "Get Loan",
      icon: <BsCashCoin />,
    },
    {
      path: "/invest",
      name: "Invest",
      icon: <BsFillBagFill />,
    },
    {
      path: "/profile",
      name: "Profile",
      icon: <CgProfile />,
    },
  ];

  const adminMenuItem = [
    {
      path: "/admin",
      name: "Home",
      icon: <FaTh />,
    },
    {
      path: "/admin-realEstate",
      name: "Real Estate",
      icon: <BsFillHouseFill />,
    },
    {
      path: "/admin-transport",
      name: "Transport",
      icon: <FaBusAlt />,
    },
    {
      path: "/admin-add",
      name: "Add Investments",
      icon: <BsFillBagFill />,
    },
    {
      path: "/admin-users",
      name: "All users",
      icon: <BsPeopleFill />,
    },
    {
      path: "/profile",
      name: "Settings",
      icon: <FiSettings />,
    },
  ];

  const handleSignOut = async () => {
    try {
      let { error } = await signOut();
      if (error === null) {
        setAuthenticated(false);
        history("/login");
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  if (user?.user?.user_metadata?.role === "ADMIN") {
    return (
      <div className="DashboardNav-con">
        <div className="sidebar open-sidenav" id="desktop-sideNav">
          <div className="sidebar-logo-main-con">
            <div className="side-logo-con">
              <img src={logo} alt="logo" className="sidebar-logo" />
            </div>
          </div>
          {adminMenuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassname="active"
            >
              <div className="icon">{item.icon}</div>
              <div className="link_text">{item.name}</div>
            </NavLink>
          ))}
          <div
            className="link"
            activeclassname="active"
            onClick={handleSignOut}
          >
            <div className="icon">
              <RiLogoutCircleLine />
            </div>
            <div className="link_text">Sign out</div>
          </div>
        </div>
        <div className={isOpen ? "mobile-sideNav color" : "mobile-sideNav mobile-sidenav-close"}>
          <div className={isOpen ? "sidebar open-sidenav" : "close-sidenav"}>
            <div className="logo-ham">
              <div className="sidebar-logo-main-con">
                <div className="side-logo-con">
                  <img src={logo} alt="logo" className="sidebar-logo" />
                </div>
              </div>
              <div className="top_section">
                <div
                  style={{ marginLeft: isOpen ? "70px" : "0px" }}
                  className="bars"
                >
                  <FaBars onClick={toggle} />
                </div>
              </div>
            </div>
            {adminMenuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className={isOpen ? "link" : "no-sidebar"}
                activeclassname="active"
                onClick={toggle}
              >
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            ))}
            <div
              className={isOpen ? "link" : "no-sidebar"}
              activeclassname="active"
              onClick={handleSignOut}
            >
              <div className="icon">
                <RiLogoutCircleLine />
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                Sign out
              </div>
            </div>
          </div>
        </div>
        <main className="navbar-children">
          {children} <Outlet />
        </main>
        <BottomNav admin={true} />
      </div>
    );
  } else {
    return (
      <div className="DashboardNav-con">
        <div className="sidebar open-sidenav" id="desktop-sideNav">
          <div className="sidebar-logo-main-con">
            <div className="side-logo-con">
              <img src={logo} alt="logo" className="sidebar-logo" />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <NavLink
              to={item.path}
              key={index}
              className="link"
              activeclassname="active"
            >
              <div className="icon">{item.icon}</div>
              <div className="link_text">{item.name}</div>
            </NavLink>
          ))}
          <div
            className="link"
            activeclassname="active"
            onClick={handleSignOut}
          >
            <div className="icon">
              <RiLogoutCircleLine />
            </div>
            <div className="link_text">Sign out</div>
          </div>
        </div>
        <div className={isOpen ? "mobile-sideNav color" : "mobile-sideNav mobile-sidenav-close"}>
          <div className={isOpen ? "sidebar open-sidenav" : "close-sidenav"}>
            <div className="logo-ham">
              <div className="sidebar-logo-main-con">
                <div className="side-logo-con">
                  <img src={logo} alt="logo" className="sidebar-logo" />
                </div>
              </div>
              <div className="top_section">
                <div
                  style={{ marginLeft: isOpen ? "70px" : "0px" }}
                  className="bars"
                >
                  <FaBars onClick={toggle} />
                </div>
              </div>
            </div>
            {menuItem.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className={isOpen ? "link" : "no-sidebar"}
                activeclassname="active"
                onClick={toggle}
              >
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            ))}
            <div
              className={isOpen ? "link" : "no-sidebar"}
              activeclassname="active"
              onClick={handleSignOut}
            >
              <div className="icon">
                <RiLogoutCircleLine />
              </div>
              <div
                style={{ display: isOpen ? "block" : "none" }}
                className="link_text"
              >
                Sign out
              </div>
            </div>
          </div>
        </div>
        <main className="navbar-children">
          {children} <Outlet />
        </main>
        <BottomNav />
      </div>
    );
  }
};

export default DashboardNav;
