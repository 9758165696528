import React from 'react'
import SingleUser from '../../components/Admin Dashboard/single user/SingleUser';

const SingleUserPage = () => {
    window.scrollTo(0, 0);
  return (
    <SingleUser />
  )
}

export default SingleUserPage