import { Spinner } from '../../spinner/Spinner';
import './styles/file-input.css';


export const FileInput = ({ onChange, uploading }) => {
  
  return (
    <div className='file-upload'>
      {uploading  ? <div className='file-spinner-con'> <Spinner /></div> :<input type="file" onChange={onChange} required/> }
    </div>
  );
};
