import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthRoutes from "./routes/AuthRoutes";
import UnAuthRoutes from "./routes/UnAuthRoutes";
import { useAuth } from "./providers/auth.provider";


function App() {
  const { authenticated } = useAuth();

  return (
    <>
      <Router>
        <Routes>
          {authenticated ? <Route path="*" element={<AuthRoutes />} /> : <Route path="*" element={<UnAuthRoutes />} />}
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;

