import React from 'react'
import Invest from '../components/DashBoard/invest/Invest';

const InvestPage = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Invest />
    </>
  )
}

export default InvestPage