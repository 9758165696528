export const investmentOption = [
    { name: "Real Estate", value: "Real Estate", id: 1 },
    { name: "Transport", value: "Transport", id: 2 },
];

export const bankOption = [
    { name: "Access bank", value: "Access bank", bankCode: '044', id: 1 },
    { name: "Ecobank", value: "Ecobank", bankCode: '050', id: 2 },
    { name: "Fidelity bank", value: "Fidelity bank", bankCode: '070', id: 3 },
    { name: "First bank of Nigeria", value: "First bank of Nigeria", bankCode: '011', id: 4 },
    { name: "First city monument bank (FCMB)", value: "First city monument bank (FCMB)", bankCode: '214', id: 5 },
    { name: "Guaranty trust bank", value: "Guaranty trust bank", bankCode: '058', id: 6 },
    { name: "Heritage bank", value: "Heritage bank", bankCode: '030', id: 7 },
    { name: "Keystone bank", value: "Keystone bank", bankCode: '082', id: 8 },
    { name: "Stanbic IBTC bank", value: "Stanbic IBTC bank", bankCode: '221', id: 9 },
    { name: "Sterling bank", value: "Sterling bank", bankCode: '232', id: 10 },
    { name: "Union bank", value: "Union bank", bankCode: '032', id: 11 },
    { name: "United bank for Africa (UBA)", value: "United bank for Africa (UBA)", bankCode: '033', id: 12 },
    { name: "Unity Bank ", value: "Unity Bank ", bankCode: '215', id: 13 },
    { name: "VFD microfinance bank", value: "VFD microfinance bank", bankCode: '090110', id: 14 },
    { name: "Wema bank", value: "Wema bank", bankCode: '035', id: 15 },
    { name: "Zenith bank", value: "Zenith bank", bankCode: '057', id: 16 },
];

export const periodOption = [
    { name: "1 month", value: "1", id: 1 },
    { name: "2 months", value: "2", id: 2 },
    { name: "3 months", value: "3", id: 3 },
    { name: "4 months", value: "4", id: 4 },
    { name: "5 months", value: "5", id: 5 },
    { name: "6 months", value: "6", id: 6 },
];

export const ROIOption = [
    { name: "12% every 3 months", value: "12% every 3 months", id: 2 },
    { name: "24% every 6 months", value: "24% every 6 months", id: 3 },
    { name: "48% every 12 months", value: "48% every 12 months", id: 4 },
];

export const durationOption = [
    { name: "1 year", value: "1", id: 1 },
    { name: "2 years", value: "2", id: 2 },
];

export const transportDurationOption = [
    { name: '1 year', value: '1', id: 1 },
    { name: '2 years', value: '2', id: 2 },
    { name: '3 years', value: '3', id: 3 },
    { name: '4 years', value: '4', id: 4 },
    { name: '5 years', value: '5', id: 5 },
];