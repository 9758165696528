import React, { useEffect } from 'react'
import { FaBell } from 'react-icons/fa'
import AllItems from '../../AllItems/AllItems';
import Categories from '../../Categories/Categories';
import { useFilterContext } from '../../Context/filter_context';
import { useProductsContext } from '../../Context/product_context';
import Footer from '../../Footer/Footer';
import { getUniqueValues } from '../../utilities/helper';

const Investment = () => {
  const { fetchProducts } = useProductsContext();
  const { filtered_products: products, updateFilters, all_products, } = useFilterContext();

  useEffect(() => {
    fetchProducts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categories = getUniqueValues(all_products, "location");
  useEffect(
    // console.log(all_products)
    () => {
      updateFilters( { target: { name: "location", textContent: "all" } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div id="dash-main-con">
      <div className='get-loan-right'>
        <section className='get-loan-right-main'>
          <div className="dash-header-main-con">
            <h2 className="real-estate-header-text">Real Estate</h2>
            <div className="bell-profile">
              <div>
                <FaBell className="bell-con" />
              </div>
            </div>
          </div>
          <Categories categories={categories} updateFilters={updateFilters} />
          <AllItems items={products} admin={true}/>
        </section>
        <div className="dash-footer-con">

          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Investment